import { sample } from 'effector';

import { userLoggedIn } from '../CRUD/authCrud';
import { confirmInformationFx, updateUserFx } from '../Members/ProfilePage';
import { 
  $loggedInUser,
  $userMSAL,
  loginGate,
  setUserMSAL,
  userLoggedInFx 
} from '.';

// Token Handler
$userMSAL.on(setUserMSAL, (_, data) => data);

// Login Handler
userLoggedInFx.use(userLoggedIn);
$loggedInUser.on(userLoggedInFx.doneData, (_, data) => data);
$loggedInUser.on(updateUserFx.doneData, (state) => ({
  ...state,
  needsUpdates: false
}));
$loggedInUser.on(confirmInformationFx.doneData, (state) => ({
  ...state,
  needsUpdates: false
}));

sample({
  clock: loginGate.open,
  filter: Boolean,
  target: userLoggedInFx
})