import { getCountries, getStates } from "country-state-picker";
import { guard, sample } from "effector";

import {
  confirmMemberInformation,
  getLoggedInUser,
  getMemberById,
  getMemberRoles,
  updateMember,
} from "../../CRUD/memberCrud";
import { historyPushFx } from "../../History";
import { getChaptersFx } from "../AddMember";
import {
  fetchLeadershipPositionsFx,
  fetchMemberStatusesFx,
} from "../ManageMembers";
import {
  $countries,
  $roles,
  $selectedMember,
  $selectedMemberCountryCode,
  $selectedMemberId,
  $states,
  EditModeApi,
  ProfileGate,
  confirmInformation,
  confirmInformationFx,
  fetchCountriesFx,
  fetchLoggedInUserFx,
  fetchMemberFx,
  fetchRolesFx,
  fetchStatesFx,
  setCountryCodeFromName,
  setSelectedMemberCountryCode,
  setSelectedMemberId,
  submitProfileUpdates,
  updateUserFx,
} from ".";

// Set Effect Calls
confirmInformationFx.use(confirmMemberInformation);
fetchMemberFx.use(getMemberById);
fetchLoggedInUserFx.use(getLoggedInUser);
fetchCountriesFx.use(getCountries);
fetchRolesFx.use(getMemberRoles);
fetchStatesFx.use(getStates);
updateUserFx.use(updateMember);

// Profile Event Handling
$selectedMemberId.on(setSelectedMemberId, (_, data) => data);
$selectedMemberCountryCode.on(setSelectedMemberCountryCode, (_, data) => data);

// Profile Effect Handling
$selectedMember
  .on(fetchMemberFx.doneData, (state, data) => ({
    ...state,
    ...data,
  }))
  .reset([fetchMemberFx]);
$selectedMember
  .on(fetchLoggedInUserFx.doneData, (state, data) => ({
    ...state,
    ...data,
  }))
  .reset([fetchLoggedInUserFx]);
$countries.on(fetchCountriesFx.doneData, (_, data) =>
  data.map(({ name, code }) => ({
    label: name,
    value: name,
    code: code,
  }))
);
$roles.on(fetchRolesFx.doneData, (_, data) => data);
$states
  .on(fetchStatesFx.doneData, (_, data) =>
    data.map((state) => ({
      label: state,
      value: state,
    }))
  )
  .reset(setCountryCodeFromName);

// Profile Event Triggers
sample({
  source: { member: $selectedMember, countries: $countries },
  clock: [
    fetchCountriesFx.doneData,
    fetchMemberFx.doneData,
    fetchLoggedInUserFx.doneData,
  ],
  filter: ({ member, countries }) =>
    member.country !== "" && countries.length !== 0,
  fn: ({ member, countries }) => {
    var country = countries.find((country) => country.value === member.country);
    return country.code;
  },
  target: setSelectedMemberCountryCode,
});

sample({
  source: $countries,
  clock: setCountryCodeFromName,
  fn: (countries, countryName) => {
    var country = countries.find((country) => country.value === countryName);
    return country.code;
  },
  target: setSelectedMemberCountryCode,
});

sample({
  clock: [ProfileGate.close, updateUserFx.doneData],
  target: EditModeApi.Inactive,
});

// Profile Effect Triggers
sample({
  clock: $selectedMemberCountryCode,
  target: fetchStatesFx,
});

sample({
  clock: setSelectedMemberId,
  fn: () => "/profile",
  target: historyPushFx,
});

guard({
  source: $selectedMemberId,
  clock: [
    updateUserFx.doneData,
    setSelectedMemberId,
    confirmInformationFx.doneData,
  ],
  filter: (selectedMemberId) =>
    selectedMemberId === null || selectedMemberId === 0,
  target: fetchLoggedInUserFx,
});

guard({
  source: $selectedMemberId,
  clock: [updateUserFx.doneData, setSelectedMemberId],
  filter: (selectedMemberId) =>
    selectedMemberId !== 0 && selectedMemberId !== null,
  target: fetchMemberFx,
});

sample({
  source: $selectedMemberId,
  clock: ProfileGate.open,
  filter: (selectedMemberId) => selectedMemberId === null,
  target: fetchLoggedInUserFx,
});

sample({
  clock: submitProfileUpdates,
  target: updateUserFx,
});

sample({
  clock: ProfileGate.open,
  target: [
    fetchCountriesFx,
    fetchRolesFx,
    fetchMemberStatusesFx,
    fetchLeadershipPositionsFx,
    getChaptersFx,
  ],
});

sample({
  clock: confirmInformation,
  target: confirmInformationFx,
});
