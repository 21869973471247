import React from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from '../msalConfig';
import {Redirect} from "react-router";
import { useStore } from "effector-react";
import { $loggedInUser } from "../../../../effector/User";

export default function Login() {
    const user = useStore($loggedInUser);
    const {instance, inProgress} = useMsal();
    const isLoggedIn = useIsAuthenticated();

    const doLogin = async () => {
        try {
            console.log('do login');
            if (inProgress === "none" && !isLoggedIn)
              await instance.loginRedirect(loginRequest);
        } catch (err) {
            // handle error
            console.error("login error");
            console.log(err);
        }
    }
    if (isLoggedIn && !user) {
        return (
            <h1>Authenticated but user not set</h1>
        );
    } else if (isLoggedIn && user) {
        return (
            <Redirect to="/home"/>
        );
    } else {
        return (
            <div className="login-form login-signin" id="kt_login_signin_form">
                {/*begin::Form*/}
                <form
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                        <button
                            id="signin_button"
                            onClick={doLogin}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>Log In</span>
                        </button>
                    </div>
                </form>
                {/*end::Form*/}
            </div>
        );
    }
}
