export const AlertText = "Your profile information may be out of date. Please update or confirm your information.";
export const ViewProfileButtonText = "View My Profile";

export const DashboardTitle = 'Welcome to Agora';

export const NewMembersTableTitle = "Chapter New Members";
export const NewMembersTableColumns = [
  {
    field: 'firstName',
    title: 'First Name',
  },
  {
    field: 'lastName',
    title: 'Last Name',
  },
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'newMemberClass',
    title: 'New Member Class',
  },
  { 
    field: 'inductionDate',
    title: 'Induction Date'
  }
];