import { combine, createDomain } from "effector";
import { createGate } from "effector-react";

const chaptersPageDomain = createDomain();

export const ChaptersPageGate = createGate('ChaptersPage');

// Chapters Page Stores
export const $chapterList = chaptersPageDomain.store([]);
export const $selectedChapter = chaptersPageDomain.store(null);
export const $showActiveChaptersOnly = chaptersPageDomain.store(true);
export const $filterText = chaptersPageDomain.store("");
export const $filteredChapters = chaptersPageDomain.store([]);
export const $selectedChapterIndex = chaptersPageDomain.store(0);
export const $chapterLeadership = chaptersPageDomain.store([]);
export const $chapterActiveMembers = chaptersPageDomain.store([]);
export const $chapterAlumniMembers = chaptersPageDomain.store([]);
export const $chapterOtherMembers = chaptersPageDomain.store([]);

export const $chapterMembers = combine(
  $chapterLeadership,
  $chapterActiveMembers,
  $chapterAlumniMembers,
  $chapterOtherMembers,
  (leadership, activeMembers, alumniMembers, otherMembers) => ({
    leadership,
    activeMembers,
    alumniMembers,
    otherMembers
  })
);

// Chapters Page Events
export const setSelectedChapter = chaptersPageDomain.event();
export const setSelectedChapterIndex = chaptersPageDomain.event();
export const setShowActiveOnly = chaptersPageDomain.event();
export const setFilterText = chaptersPageDomain.event();
export const setFilteredChapters = chaptersPageDomain.event();

// Chapters Page Effects
export const fetchChapterListFx = chaptersPageDomain.effect();
export const fetchChapterMembersFx = chaptersPageDomain.effect();