import { sample } from "effector";

import { getNewChapterMembers } from "../CRUD/memberCrud";
import { $loggedInUser } from "../User";
import { $newChapterMembers, NewMemberGate, fetchNewChapterMembersFx } from ".";


fetchNewChapterMembersFx.use(getNewChapterMembers);

$newChapterMembers.on(fetchNewChapterMembersFx.doneData, (_, data) => data);

sample({
  clock: NewMemberGate.open,
  source: $loggedInUser,
  filter: (loggedInUser) => loggedInUser !== null,
  fn: ({loggedInUser}) => ({
    chapterId: loggedInUser.currentChapterId
  }),
  target: fetchNewChapterMembersFx
});