import React from 'react';

import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import { useStore } from 'effector-react';

import { $isReportLoading, $reportData, $selectedReport } from '../../../effector/Reports';
import { exportFileName, getReportColumns, getReportTitle } from './ReportsHelpers';

const ReportsTable = () => {
  const reportData = useStore($reportData);
  const selectedReport = useStore($selectedReport);
  const isLoading = useStore($isReportLoading);

  return (
    <MaterialTable 
      title={getReportTitle(selectedReport)}
      columns={getReportColumns(selectedReport)}
      data={reportData}
      isLoading={isLoading}
      options={{
        exportMenu: [
          {
            label: 'Export CSV',
            exportFunc: (cols) => ExportCsv(cols, reportData, exportFileName(selectedReport))
          }
        ],
        headerStyle: {
          backgroundColor: '#6993FF',
          color: '#fff',
          fontSize: '12px'
        },
        pageSize: 20
      }}
    />
  );
};

export default ReportsTable;