import React from "react";

import { useStore } from "effector-react";
import {
  BugReport,
  Cancel,
  LockReset,
  ModeEdit,
  Save,
  ThumbUpAltSharp,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";

import {
  $profilePageParams,
  DebugInfoApi,
  EditModeApi,
  confirmInformation,
  submitProfileUpdates,
} from "../../../../effector/Members/ProfilePage";
import { resetPasswordFx } from "../../../../effector/Members/ManageMembers";
import { $loggedInUser } from "../../../../effector/User";
import { UserRoles } from "../../../Enums";
import { ButtonLabels } from "../constants";
import { useStyles } from "./ProfilePageStyles";

export const Header = ({ resetForm, onSubmit }) => {
  const { loggedInUser, needsUpdates } = useStore($loggedInUser);
  const { selectedMember, isEditMode, isSaving, showDebugInfo } = useStore(
    $profilePageParams
  );

  const classes = useStyles();

  const allowConfirmInfo = selectedMember.memberId === loggedInUser.memberId;

  const allowEditing = () => {
    // A user may edit their own profile
    if (loggedInUser?.memberId === selectedMember?.memberId) return true;

    // Admins may edit profiles
    if (loggedInUser?.roleId === UserRoles.Admin) return true;

    // Chapter Executives can edit profiles for members of their chapter
    if (
      loggedInUser?.roleId === UserRoles.Executive &&
      loggedInUser?.currentChapterId &&
      selectedMember?.currentChapterId &&
      loggedInUser?.currentChapterId === selectedMember?.currentChapterId
    )
      return true;

    // Chapter Support members can edit profiles for all members
    if (loggedInUser?.roleId === UserRoles.ChapterSupport) return true;

    // default case is no edits
    return false;
  };

  const allowResetPassword = () => {
    if (isEditMode) return false;

    if (
      loggedInUser?.roleId === UserRoles.Admin ||
      loggedInUser?.roleId === UserRoles.ChapterSupport
    )
      return true;

    return false;
  };

  const debugLabel = showDebugInfo
    ? ButtonLabels.hideDebug
    : ButtonLabels.showDebug;

  const handleDebugClick = () =>
    showDebugInfo ? DebugInfoApi.hide() : DebugInfoApi.show();

  const onSubmitHandler = (data) => submitProfileUpdates(data);

  return (
    <div>
      <Typography variant="h3" gutterBottom>
        {selectedMember.firstName + " " + selectedMember.lastName}
      </Typography>
      <Typography className="mb-2" gutterBottom>
        {showDebugInfo &&
          `AzureID: ${selectedMember?.azureId} / AgoraID: ${selectedMember?.memberId}`}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center">
        {isEditMode && (
          <>
            <Button
              className={`${classes.button} btn btn-danger font-weight-bold my-3`}
              onClick={() => {
                resetForm();
                EditModeApi.Inactive();
              }}
              startIcon={<Cancel />}
            >
              <span>{ButtonLabels.cancel}</span>
            </Button>
            <LoadingButton
              loading={isSaving}
              loadingPosition="start"
              className={`${classes.button} btn btn-primary font-weight-bold my-3`}
              onClick={onSubmit(onSubmitHandler)}
              startIcon={<Save />}
            >
              <span>{ButtonLabels.save}</span>
            </LoadingButton>
          </>
        )}
        {allowEditing() && !isEditMode && (
          <Button
            className={`${classes.button} btn btn-primary font-weight-bold my-3`}
            onClick={() => EditModeApi.Active()}
            startIcon={<ModeEdit />}
          >
            <span>{ButtonLabels.edit}</span>
          </Button>
        )}
        {allowConfirmInfo && needsUpdates && !isEditMode && (
          <Button
            className={`${classes.button} btn btn-primary font-weight-bold my-3`}
            onClick={() => confirmInformation()}
            startIcon={<ThumbUpAltSharp />}
          >
            <span>{ButtonLabels.confirmInfo}</span>
          </Button>
        )}
        {allowResetPassword() && !isEditMode && (
          <Button
            className={`${classes.button} btn btn-primary font-weight-bold my-3`}
            id="reset-password"
            onClick={() => resetPasswordFx(selectedMember.memberId)}
            startIcon={<LockReset />}
          >
            <span>{ButtonLabels.resetPassword}</span>
          </Button>
        )}
        {loggedInUser?.roleId === UserRoles.Admin && !isEditMode && (
          <Button
            className={`${classes.button} btn btn-primary font-weight-bold my-3`}
            id="debug"
            onClick={handleDebugClick}
            startIcon={<BugReport />}
          >
            <span>{debugLabel}</span>
          </Button>
        )}
      </Stack>
    </div>
  );
};
