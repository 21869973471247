import React from 'react';
import { useStore } from "effector-react";

import { Alert, List, ListItemButton, ListItemText, Paper, Typography } from "@mui/material";

import { $chapterMembers } from "../../../../effector/Chapters/ChaptersPage";
import { setSelectedMemberId } from "../../../../effector/Members/ProfilePage";
import { UserStatusCssClasses, chapterMemberListLabels } from "../constants";
import { useStyles } from './ChapterPageStyles';

export const ChapterMemberList = () => {
  const { leadership, activeMembers, alumniMembers, otherMembers } = useStore($chapterMembers);

  const classes = useStyles();
  
  return (
    <div className={`${classes.chapterPage} p-5`}>
      <Typography className="mt-4" variant="h5" gutterBottom>
        {chapterMemberListLabels.chapterLeadership}
      </Typography>
      {leadership.length === 0 && (
        <Alert severity="info" variant="standard">No Members are in this chapter's leadership</Alert>
      )}
      {leadership.length > 0 && (
        <Paper>
          <List component="nav" aria-label="Chapter Leadership" className={classes.smallListStyle}>
            {leadership && leadership.map((member) => (
              <ListItemButton key={'ch-m-' + member?.memberId}>
                <ListItemText primary={member.memberName} onClick={() => setSelectedMemberId(member.memberId)} />
                <span
                    className={`label label-lg label-light-primary label-inline`}
                >
                  {member.chapterLeadershipPosition ? member.chapterLeadershipPosition : 'Not Set'}
                </span>
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )}
      <Typography className="mt-4" variant="h5" gutterBottom>
        {`${chapterMemberListLabels.activeMembers} ${activeMembers.length === 0 ? '' : "(" + activeMembers.length + ")"}`}
      </Typography>
      {activeMembers.length === 0 && (
        <Alert severity="info" variant="standard">No members in this chapter are active</Alert>
      )}
      {activeMembers.length > 0 && (
        <Paper>
          <List component="nav" aria-label="Active Members" className={classes.smallListStyle}>
            {activeMembers && activeMembers.map((member) => (
              <ListItemButton key={'ch-m-' + member?.memberId}>
                <ListItemText primary={member.memberName} onClick={() => setSelectedMemberId(member.memberId)} />
                <span
                    className={`label label-lg label-light-${
                        member.memberStatusName ? UserStatusCssClasses[member.memberStatusName] : 'danger'
                    } label-inline`}
                >
                  {member.memberStatusName ? member.memberStatusName : 'Not Set'}
                </span>
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )}
      <Typography className="mt-4" variant="h5" gutterBottom>
        {`${chapterMemberListLabels.alumniMembers} ${alumniMembers.length === 0 ? '' : "(" + alumniMembers.length + ")"}`}
      </Typography>
      {alumniMembers.length === 0 && (
        <Alert severity="info" variant="standard">No alumni are in this chapter</Alert>
      )}
      {alumniMembers.length > 0 && (
        <Paper>
          <List component="nav" aria-label="Alumni Members" className={classes.smallListStyle}>
            {alumniMembers && alumniMembers.map((member) => (
              <ListItemButton key={'ch-m-' + member?.memberId}>
                <ListItemText primary={member.memberName} onClick={() => setSelectedMemberId(member.memberId)} />
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )}
      <Typography className="mt-4" variant="h5" gutterBottom>
        {`${chapterMemberListLabels.otherMembers} ${otherMembers.length === 0 ? '' : "(" + otherMembers.length + ")"}`}
      </Typography>
      {otherMembers.length === 0 && (
        <Alert severity="info" variant="standard">No Other Members are in this chapter</Alert>
      )}
      {otherMembers.length > 0 && (
        <Paper>
          <List component="nav" aria-label="Other Members" className={classes.smallListStyle}>
            {otherMembers && otherMembers.map((member) => (
              <ListItemButton key={'ch-m-' + member?.memberId}>
                <ListItemText primary={member.memberName} onClick={() => setSelectedMemberId(member.memberId)} />
                <span
                    className={`label label-lg label-light-${
                        member.memberStatusName ? UserStatusCssClasses[member.memberStatusName] : 'danger'
                    } label-inline`}
                >
                  {member.memberStatusName ? member.memberStatusName : 'Not Set'}
                </span>
              </ListItemButton>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
}

