import { createApi, createDomain } from "effector";

const membersDomain = createDomain();

// Bulk Create Modal
export const $isBulkCreateModalOpen = membersDomain.store(false);
export const BulkCreateMemberModalApi = createApi($isBulkCreateModalOpen, {
  open: () => true,
  close: () => false,
});

export const $selectedFile = membersDomain.store(null);
export const setSelectedFile = membersDomain.event();
export const bulkCreateMembersEvent = membersDomain.event();
export const bulkCreateMembersFx = membersDomain.effect();