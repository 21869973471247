export const DropdownLabel = "Select Report:";

export const ChapterSupportReports = [
  {value: 'cs-actives', label: 'Active/New Member Roster'},
  {value: 'cs-chapterMemberCount', label: 'Chapter Member Count'},
  {value: 'cs-leadershipEmails', label: 'Chapter Leadership Email Addresses'},
  {value: 'cs-recentAlumni', label: 'Recent Alumni (< 6 months)'}
];

export const MarketingReports = [
  {value: 'mkt-email', label: 'Active/Alumni Email Addresses'}
];

export const MenuSubheaderLabels = {
  marketing: 'Marketing Reports',
  chapterSupport: 'Chapter Support Reports'
};

export const ReportTitle = {
  csActives: 'Active/New Member Roster',
  csLeadershipEmails: 'Chapter Leadership Email Addresses',
  csChapterMemberCount: 'Chapter Member Count',
  marketingEmail: 'Active/Alumni Email Addresses',
  csRecentAlumni: 'Recent Alumni (< 6 months)'
};

export const MarketingEmailColumns = [
  {
    field: "firstName",
    title: "First Name",
  },
  {
    field: "lastName",
    title: "Last Name",
    defaultSort: 'asc'
  },
  {
    field: "status",
    title: "Member Status",
  },
  {
    field: "email",
    title: "Email",
  }
];

export const LeadershipEmailColumns = [
  {
    field: "firstName",
    title: "First Name",
  },
  {
    field: "lastName",
    title: "Last Name",
  },
  {
    field: "chapter",
    title: "Chapter",
    defaultSort: 'asc'
  },
  {
    field: "position",
    title: "Chapter Position"
  },
  {
    field: "email",
    title: "Email"
  }
];

export const ActiveRosterColumns = [
  {
    field: "firstName",
    title: "First Name"
  },
  {
    field: "lastName",
    title: "Last Name",
    defaultSort: 'asc'
  },
  {
    field: "status",
    title: "Member Status",
  },
  {
    field: "chapter",
    title: "Chapter",
  },
  {
    field: "position",
    title: "Chapter Position"
  },
  {
    field: "email",
    title: "Email"
  }
];

export const RecentAlumniColumns = [
  {
    field: "firstName",
    title: "First Name"
  },
  {
    field: "lastName",
    title: "Last Name",
    defaultSort: 'asc'
  },
  {
    field: "email",
    title: "Email"
  },
  {
    field: "currentChapterName",
    title: "Chapter"
  },
  {
    field: "updatedDate",
    title: "Date Went Alumni"
  }
];

export const ChapterMemberCountColumns = [
  {
    field: "chapterName",
    title: "Chapter Name",
    defaultSort: 'asc'
  },
  {
    field: "numOfActives",
    title: "# of Active Members"
  },
  {
    field: "numOfNewMembers",
    title: "# of New Members"
  },
  {
    field: "numOfAlumnus",
    title: "# of Alumnus Members"
  }
];
