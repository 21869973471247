import { combine, createApi } from "effector";

import { chapterManagementDomain } from "../ManageChapters";

// Add Chapter Modal Open State/Handler
export const $isAddChapterModalOpen = chapterManagementDomain.store(false);
export const addChapterModalApi = createApi($isAddChapterModalOpen, {
    open: () => true,
    close: () => false
});

// Edit Chapter Modal Open State/Handler
export const $isEditChapterModalOpen = chapterManagementDomain.store(false);
export const editChapterModalApi = createApi($isEditChapterModalOpen, {
    open: () => true,
    close: () => false
});

// Add/Edit Chapter Modal Stores
export const $modalChapter = chapterManagementDomain.store({
    chapterId: 0,
    chapterName: "",
    university: "",
    location: "",    
    chapterTypeId: 0,
    chapterRegionId: 0,
    isActive: true
}).reset([addChapterModalApi.close, editChapterModalApi.close]);
export const $modalError = chapterManagementDomain.store("")
  .reset([addChapterModalApi.close, editChapterModalApi.close]);

export const $chapterRegions = chapterManagementDomain.store([]);
export const $chapterTypes = chapterManagementDomain.store([]);

// Add/Edit Chapter Modal Events
export const addChapter = chapterManagementDomain.event();
export const setSelectedChapterToUpdate = chapterManagementDomain.event();
export const updateSelectedChapter = chapterManagementDomain.event();

// Add/Edit Chapter Modal Effects
export const fetchChapterRegionsFx = chapterManagementDomain.effect();
export const fetchChapterTypesFx = chapterManagementDomain.effect();
export const addNewChapterFx = chapterManagementDomain.effect();
export const updateChapterFx = chapterManagementDomain.effect();

// Add/Edit Chapter Modal Params Store
export const $modalProps = combine(
  $modalChapter,
  $chapterRegions,
  $chapterTypes,
  $modalError,
  (modalValues, regions, types, error) => ({
    modalValues,
    regions,
    types,
    error
  })
);