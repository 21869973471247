import React from 'react';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useStore } from 'effector-react';

import { $selectedChapter } from '../../../../effector/Chapters/ChaptersPage';
import { chapterInfoLabels } from '../constants';

export const ChapterInformation = () => {
  const chapter = useStore($selectedChapter);

  const getChapterStatus = () => {
    if (chapter.isActive)
      return "ACTIVE";
    else
      return "INACTIVE";
  };

  return (
    <>
      <Grid className="pb-2" container spacing={1}>
        <Grid item xs={12}>
          <Typography className="mb-2 text-center font-weight-bold" variant="h3" gutterBottom>
            {chapter && chapter.chapterName}
          </Typography>
          <Divider variant="middle"/>
        </Grid>
        <Grid item xs={5}>
          <Typography className="text-center font-weight-bold" variant="h5" gutterBottom>
            {chapterInfoLabels.university}
          </Typography>
          <Typography className="text-center" variant="h6" gutterBottom>
            {chapter && chapter.university}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text-center font-weight-bold" variant="h5" gutterBottom>
            {chapterInfoLabels.city}
          </Typography>
          <Typography className="text-center" variant="h6" gutterBottom>
            {chapter && chapter.location}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text-center font-weight-bold" variant="h5" gutterBottom>
            {chapterInfoLabels.region}
          </Typography>
          <Typography className="text-center" variant="h6" gutterBottom>
            {chapter && chapter.region}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="text-center font-weight-bold" variant="h5" gutterBottom>
            {chapterInfoLabels.status}
          </Typography>
          <Typography className="text-center" variant="h6" gutterBottom>
            {chapter && getChapterStatus()}
          </Typography>
        </Grid>
      </Grid>
      <Divider variant="middle"/>
    </>
  );
}