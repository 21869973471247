import React from "react";

import { Divider, Grid, Paper } from "@mui/material"; 
import { useGate, useStore } from "effector-react";

import { ChaptersPageGate, fetchChapterMembersFx } from "../../../effector/Chapters/ChaptersPage";
import { ChapterInformation } from "./components/ChapterInformation";
import { ChapterList } from "./components/ChapterList";
import { ChapterMemberList } from "./components/ChapterMemberList";
import { useStyles } from "./components/ChapterPageStyles";

export const ChapterPage = () => {
  useGate(ChaptersPageGate);

  const isMembersLoading = useStore(fetchChapterMembersFx.pending);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <ChapterList />
        </Grid>
        <Grid item xs={9}>
          <Paper className={classes.wrappingPaper}>
            <ChapterInformation />
            <Divider variant="middle" />
            {isMembersLoading ? (
              <h1>Loading...</h1>
            ) : (
              <ChapterMemberList />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
