import React from 'react';

import { Alert, Button } from '@mui/material';

import { setSelectedMemberId } from '../../../../effector/Members/ProfilePage';
import { AlertText, ViewProfileButtonText } from '../constants';

export const UpdateProfileAlert = () => {
  return (
    <Alert severity="warning" style={{marginBottom: '16px'}} action={
      <Button variant="outlined" onClick={() => {
        setSelectedMemberId(0);
      }}>
        {ViewProfileButtonText}
      </Button>
    }>
      {AlertText}
    </Alert>
  );
}