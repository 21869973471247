import React from "react";

import { Paper } from "@mui/material";
import { useStore } from "effector-react";

import { $loggedInUser } from "../../../effector/User";
import { DashboardTitle } from "./constants";
import { NewMembersTable } from "./components/NewMembersTable";
import { UpdateProfileAlert } from "./components/UpdateProfileAlert";


export function DashboardPage() {
  const { loggedInUser, needsUpdates } = useStore($loggedInUser);

  return (
      <Paper className="p-4">
          <div className="card card-custom gutter-b example">
              <div className="card-header">
                  <div className="card-title">
                      <h3 className="card-label">{DashboardTitle}</h3>
                  </div>
              </div>
              <div className="card-body">
                  {needsUpdates && (
                    <UpdateProfileAlert />
                  )}
                  {loggedInUser && (
                    <NewMembersTable />
                  )}                  
              </div>
          </div>
      </Paper>
  );
}
