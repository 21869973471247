import { sample } from "effector";

import { MemberStatuses } from "../../app/Enums";
import { getMemberReports, getRecentAlumni } from "../CRUD/memberCrud";
import { $allMembers, $reportData, $selectedReport, ReportsGate, fetchAllMembersFx, fetchChapterMemberCountFx, fetchRecentAlumniFx, setSelectedReport } from ".";
import { getChapterMemberCount } from "../CRUD/chapterCrud";

fetchAllMembersFx.use(getMemberReports);
fetchRecentAlumniFx.use(getRecentAlumni);
fetchChapterMemberCountFx.use(getChapterMemberCount);

$allMembers.on(fetchAllMembersFx.doneData, (_, data) => data);
$selectedReport.on(setSelectedReport, (_, data) => data);
$reportData.on(fetchRecentAlumniFx.doneData, (_, data) => data.filter(member => member.lastName !== "Test"));
$reportData.on(fetchChapterMemberCountFx.doneData, (_, data) => data);

sample({
  clock: setSelectedReport,
  source: $allMembers,
  filter: (_, selectedReport) => selectedReport === 'mkt-email',
  fn: (members) => {
    var reportData = members.filter(member => 
      ((member.memberStatusId === MemberStatuses.Active || member.memberStatusId === MemberStatuses.Alumnus || 
        member.memberStatusId === MemberStatuses.NewMember) && member.lastName !== "Test")
    ).map(member => ({
      firstName: member.firstName,
      lastName: member.lastName,
      status: member.memberStatusName,
      email: member.email
    }));

    return reportData
  },
  target: $reportData
});

sample({
  clock: setSelectedReport,
  source: $allMembers,
  filter: (_, selectedReport) => selectedReport === 'cs-leadershipEmails',
  fn: (members) => {
    var reportData = members.filter(member => member.chapterLeadershipPosition !== "" && member.lastName !== "Test").map(member => ({
      firstName: member.firstName,
      lastName: member.lastName,
      chapter: member.currentChapterName,
      position: member.chapterLeadershipPosition,
      email: member.email
    }));

    return reportData
  },
  target: $reportData
});

sample({
  clock: setSelectedReport,
  source: $allMembers,
  filter: (_, selectedReport) => selectedReport === 'cs-actives',
  fn: (members) => {
    var reportData = members.filter(member => 
      ((member.memberStatusId === MemberStatuses.Active || member.memberStatusId === MemberStatuses.NewMember) 
        && member.lastName !== "Test")
    ).map(member => ({
      firstName: member.firstName,
      lastName: member.lastName,
      chapter: member.currentChapterName,
      position: member.chapterLeadershipPosition,
      status: member.memberStatusName,
      email: member.email
    }));

    return reportData
  },
  target: $reportData
});

sample({
  clock: setSelectedReport,
  filter: (_, selectedReport) => selectedReport === 'cs-recentAlumni',
  target: fetchRecentAlumniFx
});

sample({
  clock: setSelectedReport,
  filter: (_, selectedReport) => selectedReport === 'cs-chapterMemberCount',
  target: fetchChapterMemberCountFx
});

sample({
  clock: ReportsGate.open,
  target: fetchAllMembersFx
});