/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {BrowserRouter} from "react-router-dom";

import {I18nProvider} from "../_metronic/i18n";
import {LayoutSplashScreen, MaterialThemeProvider} from "../_metronic/layout";
import {Routes} from "../app/Routes";
import "../effector/init";
export default function App({basename}) {    
    return (
      <React.Suspense fallback={<LayoutSplashScreen/>}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                      {/* Render routes with provided `Layout`. */}
                          <Routes/>
                  </I18nProvider>
              </MaterialThemeProvider>
          </BrowserRouter>
      </React.Suspense>
    );
}
