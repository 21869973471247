import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useGate } from "effector-react";

import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { loginGate } from "../effector/User";
import { AppSnackbar } from "./modules/Common/Snackbar/Snackbar";
import { ChapterPage } from "./pages/ChaptersPage/ChapterPage";
import { DashboardPage } from "./pages/DashboardPage/DashboardPage";
import { MemberDirectoryPage } from "./pages/DirectoryPage/DirectoryPage";
import { ProfilePage } from "./pages/ProfilePage/ProfilePage";
import ReportsPage from "./pages/ReportsPage/ReportsPage";

const AdminRouting = lazy(() => import("./pages/Admin/AdminRouting"));

export default function BasePage() {
  useGate(loginGate);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /home. */
          <Redirect exact from="/" to="/home" />
        }
        <ContentRoute path="/home" component={DashboardPage} />
        <ContentRoute path="/chapters" component={ChapterPage} />
        <ContentRoute path="/directory" component={MemberDirectoryPage} />
        <ContentRoute path="/profile" component={ProfilePage} />
        <Route path="/reports" component={ReportsPage} />
        <Route path="/admin" component={AdminRouting} />
        <Redirect to="error/error-v1" />
      </Switch>
      <AppSnackbar />
    </Suspense>
  );
}
