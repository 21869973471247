import { ActiveRosterColumns, ChapterMemberCountColumns, LeadershipEmailColumns, MarketingEmailColumns, RecentAlumniColumns, ReportTitle } from "./constants";

export const getReportColumns = (selectedReport) => {
  switch (selectedReport) {
    case 'cs-actives':
      return ActiveRosterColumns;
    case 'cs-chapterMemberCount':
      return ChapterMemberCountColumns;
    case 'cs-leadershipEmails':
      return LeadershipEmailColumns;
    case 'cs-recentAlumni':
      return RecentAlumniColumns;
    case 'mkt-email':
      return MarketingEmailColumns;
    default:
      return [];
  }
};

export const getReportTitle = (selectedReport) => {
  switch (selectedReport) {
    case 'cs-actives':
      return ReportTitle.csActives;
    case 'cs-chapterMemberCount':
      return ReportTitle.csChapterMemberCount;
    case 'cs-leadershipEmails':
      return ReportTitle.csLeadershipEmails;
    case 'cs-recentAlumni':
      return ReportTitle.csRecentAlumni;
    case 'mkt-email':
      return ReportTitle.marketingEmail;
    default:
      return ''
  }
};

export const exportFileName = (selectedReport) => {
  const date = new Date().toLocaleDateString();
  const time = new Date().toLocaleTimeString().slice(0, -3);
  switch (selectedReport) {
    case 'cs-actives':
      return `ActiveRoster_${date}_${time}`;
    case 'cs-chapterMemberCount':
      return `ChapterMemberCount_${date}_${time}`;
    case 'cs-leadershipEmails':
      return `ChapterLeadershipEmails_${date}_${time}`;
    case 'cs-recentAlumni':
      return `RecentAlumni_${date}_${time}`;
    case 'mkt-email':
      return `Active+AlumniEmailAddresses_${date}_${time}`;
    default:
      return '';
  }
}