import React from "react";
import {useMsal} from "@azure/msal-react";

export default function Logout() {
  const { instance } = useMsal();
  localStorage.removeItem('apiUserData');
  instance.logout();
  return (
      <div className="d-flex align-items-center">
        <div className="mr-2 text-muted">Logging out...</div>
        <div className="spinner spinner-primary mr-10"></div>
      </div>
  );
}
