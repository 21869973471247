import React from 'react';

import { Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Paper from "@mui/material/Paper";
import { useGate, useStore } from 'effector-react';
import { useForm } from 'react-hook-form';

import { $profilePageParams, ProfileGate } from "../../../effector/Members/ProfilePage";
import { $loggedInUser } from '../../../effector/User';
import { UserRoles } from '../../Enums';

import { 
  AdminInfoSection, 
  ContactInfoSection, 
  EmergencyContactInfoSection, 
  FraternityInfoSection, 
  Header, 
  PersonalInfoSection 
} from './components';
import { useStyles } from './components/ProfilePageStyles';


export const ProfilePage = () => {
    useGate(ProfileGate);
    const { loggedInUser } = useStore($loggedInUser);
    const { selectedMember, isEditMode, isLoading } = useStore($profilePageParams);
    const { control, handleSubmit, reset } = useForm({
      values: selectedMember
    });

    const classes = useStyles();

    const canSeeAdminData = loggedInUser && (
      loggedInUser.roleId === UserRoles.Admin || loggedInUser.roleId === UserRoles.ChapterSupport || loggedInUser.roleId === UserRoles.Office
    );

    if (isLoading) {
        return <CircularProgress />;
    }

    if (selectedMember !== null)
      document.title = `Agora - ${selectedMember?.firstName} ${selectedMember?.lastName}`;

    return (
        <Paper>
            <div className={classes.header}>
                <Header resetForm={reset} onSubmit={handleSubmit} />
            </div>
            <form
                className={'p-8'}
                autoComplete={'off'}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <PersonalInfoSection control={control} isEditMode={isEditMode} />
                      <ContactInfoSection control={control} isEditMode={isEditMode} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FraternityInfoSection control={control} isEditMode={isEditMode} />
                      <EmergencyContactInfoSection control={control} isEditMode={isEditMode} />
                      {canSeeAdminData && (
                        <AdminInfoSection control={control} isEditMode={isEditMode} />
                      )}
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
}
