import React from "react";

import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { useStore } from "effector-react";

import {
  $alertContents,
  $isSnackbarOpen,
  snackbarApi,
} from "../../../../effector/Snackbar";

export const AppSnackbar = () => {
  const isOpen = useStore($isSnackbarOpen);
  const { message, severity } = useStore($alertContents);

  const handleClose = () => {
    snackbarApi.hide();
  };

  return (
    <Snackbar 
      open={isOpen} 
      autoHideDuration={4000} 
      onClose={handleClose} 
      anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
