import React from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormSearchSelect = ({name, className, control, disabled, fullWidth, label, items, rules, style, size = "medium"}) => {
  return (
    <Controller 
      name={name}
      control={control}
      rules={{...rules}}
      render={({
        field: { onChange, value },
        fieldState: {error},
      }) =>
      (
        <Autocomplete 
          disablePortal={false}
          id={`combo-box-${label}`}
          options={items}
          size={size}
          onChange={(_, newValue) => onChange(newValue.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              id={`input-${label}`}
              autoComplete='off'
              helperText={error?.message}
              disabled={disabled}
              error={!!error}
              className={className || undefined}
              fullWidth={fullWidth}
              value={value}
              label={label}
              style={{...style}}
              type={'text'}
              InputLabelProps={{shrink: true}}
              variant="outlined"
              margin="dense"
              size={size}
            />
          )}
        />
      )}
    />
  )
}