import { attach, createDomain } from "effector";

const historyDomain = createDomain('history');

export const $history = historyDomain.store(null);

export const storeHistory = historyDomain.event();

export const historyPushFx = attach({
  source: $history,
  effect: (history, to) => {
    history.push(to);
  }
});