import { sample } from "effector";

import { bulkCreateMembers } from "../CRUD/memberCrud";
import { $userMSAL } from "../User";
import {
  $selectedFile,
  bulkCreateMembersEvent,
  bulkCreateMembersFx,
  setSelectedFile,
} from ".";

// Bulk Create Modal
bulkCreateMembersFx.use(bulkCreateMembers);

$selectedFile.on(setSelectedFile, (_, data) => new Blob([data]));

sample({
  source: { data: $selectedFile, token: $userMSAL },
  clock: bulkCreateMembersEvent,
  fn: ({ data }) => {
    return {
      data
    };
  },
  target: bulkCreateMembersFx
});


