import React from "react";

import { List, ListItemButton, ListItemText, Paper, TextField } from "@mui/material";
import { useStore } from "effector-react";

import { 
  $filteredChapters,
  $selectedChapterIndex, 
  $showActiveChaptersOnly, 
  setFilterText, 
  setSelectedChapter, 
  setSelectedChapterIndex, 
  setShowActiveOnly 
} from "../../../../effector/Chapters/ChaptersPage";
import { filterLabels } from "../constants";
import { useStyles } from "./ChapterPageStyles";

export const ChapterList = () => {
  const activeOnly = useStore($showActiveChaptersOnly);
  const chapters = useStore($filteredChapters);
  const selectedChapterIndex = useStore($selectedChapterIndex);

  const classes = useStyles();

  const setItem = (index, chapter) => {
    setSelectedChapterIndex(index);
    setSelectedChapter(chapter);
  }
  
  return (
    <Paper className={classes.wrappingPaper}>
      <div className={classes.filters}>
        {filterLabels.title}
        <TextField
          label={filterLabels.chapterName}
          margin={'dense'}
          onChange={(e) => setFilterText(e.target.value)}
          InputLabelProps={{shrink: true}}
          variant="outlined"
        />
        <label className="checkbox checkbox-lg checkbox-single">
          <input type="checkbox" checked={activeOnly} onChange={() => setShowActiveOnly(!activeOnly)} /><span className={classes.checkPad} />
          {filterLabels.activeOnly}
        </label>
      </div>
      <hr />
      <List component="nav" aria-label="Chapters" className={classes.sideListStyle}>
          {chapters && chapters.length > 0 && chapters.map((lineItem, i) => (
              <ListItemButton key={'ch-' + lineItem.chapterId} selected={selectedChapterIndex === i}>
                  <ListItemText onClick={() => setItem(i, lineItem)} primary={lineItem.chapterName}/>
              </ListItemButton>
          ))}
      </List>
    </Paper>
  );
}