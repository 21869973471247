import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useStore } from 'effector-react';

import { $profilePageParams } from '../../../../effector/Members/ProfilePage';
import { $loggedInUser } from '../../../../effector/User';
import { UserRoles } from '../../../Enums';
import { FormTextInput as Input, FormSelect as Select } from '../../common/components/Forms';
import { labels } from '../constants';
import { useStyles } from './ProfilePageStyles';

export const AdminInfoSection = ({ control, isEditMode }) => {
  const { contentContainer, inputField } = useStyles();

  const { roles } = useStore($profilePageParams);
  const { loggedInUser } = useStore($loggedInUser);

  const canEditRole = loggedInUser && (loggedInUser.roleId === UserRoles.Admin && isEditMode);

  return (
    <div className={contentContainer}>
      <Typography className='mb-2' variant='h5' gutterBottom>
          {labels.adminInfoSectionHeader}
      </Typography>
      <Grid
        container
        key={`group-contactInfo`}
        direction='row'
        justify='flex-start'
        spacing={1}
        alignItems='flex-start'
      >
        <Grid item xs={6}>
          <Select 
            name='roleId'
            control={control}
            disabled={!canEditRole}
            label={labels.role}
            items={roles}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='lastLoginDate'
            control={control}
            className={inputField}
            disabled
            label={labels.lastLoginDate}
            size='small'
            type='datetime-local'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='createdDate'
            control={control}
            className={inputField}
            disabled
            label={labels.createdDate}
            size='small'
            type='datetime-local'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='updatedDate'
            control={control}
            className={inputField}
            disabled
            label={labels.lastUpdated}
            size='small'
            type='datetime-local'
          />
        </Grid>
      </Grid>
    </div>
  );
}