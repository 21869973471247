import React from 'react';
import { Grid } from '@mui/material';

import { $isLoading, $memberFilterParams, filterMembers, setMemberFilter } from '../../../../../effector/Members/ManageMembers';
import { FilterInput as Input, FilterSelect as Select } from '../../../common/components/Filters';
import { labels } from '../constants';
import { useStore } from 'effector-react';

export const MembersFilter = () => {
  const { memberFilter, statuses, positions, chapters } = useStore($memberFilterParams);
  const membersLoading = useStore($isLoading);

  const searchTimeout = React.useRef();

  const applyFilterDelay = (values) => {
    setMemberFilter(values);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
        filterMembers();
    }, 800);
  }

  const applyFilter = (values) => {
    setMemberFilter(values);
    filterMembers();
  }

  return (
    <>
      <Grid container spacing={1} style={{paddingBottom: '8px'}}>
        <Grid item xs={1.5}>
          <Select
            name="statusId"
            label={labels.filterByStatus}
            items={statuses}
            value={memberFilter.statusId}
            disabled={membersLoading}
            onChange={(data) => applyFilter({statusId: data})}
          />
        </Grid>
        <Grid item xs={4}>
          <Select 
            name="chapterId"
            label={labels.filterByChapter}
            items={chapters}
            value={memberFilter.chapterId}
            disabled={membersLoading}
            onChange={(data) => applyFilter({chapterId: data})}
          />
        </Grid>
        <Grid item xs={2.5}>
          <Select 
            name="chapterLeadershipId"
            label={labels.filterByPosition}
            items={positions}
            value={memberFilter.chapterLeadershipId}
            disabled={membersLoading}
            onChange={(data) => applyFilter({chapterLeadershipId: data})}
          />
        </Grid>
        <Grid item xs={4}>
          <Input 
            name="searchText"
            label={labels.searchByName}
            value={memberFilter.searchText}
            disabled={membersLoading}
            onChange={(data) => applyFilterDelay({searchText: data})}
          />
        </Grid>
      </Grid>
    </>
  );
}