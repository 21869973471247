import { combine, createApi, createDomain } from "effector";
import { createGate } from "effector-react";

import { userLoggedInFx } from "../../User";
import { $chapters, getChaptersFx } from "../AddMember";
import {
  $leadershipPositions,
  $memberStatuses,
  fetchLeadershipPositionsFx,
  fetchMemberStatusesFx,
} from "../ManageMembers";

export const ProfileGate = createGate("Profile");
const profileDomain = createDomain("Profile");
profileDomain.onCreateStore((store) => store.reset(ProfileGate.close));

// Edit Mode and Debug Info Handling
export const $isEditMode = profileDomain.store(false);
export const $showDebugInfo = profileDomain.store(false);

export const DebugInfoApi = createApi($showDebugInfo, {
  show: () => true,
  hide: () => false,
});

export const EditModeApi = createApi($isEditMode, {
  Active: () => true,
  Inactive: () => false,
});

// Profile Page stores
export const $selectedMember = profileDomain.store({
  memberId: 0,
  createdDate: "",
  updatedDate: "",
  azureId: "",
  currentChapterId: 0,
  newMemberClass: "",
  initiationChapterId: 0,
  preferredName: "",
  firstName: "",
  lastName: "",
  dlpUnifiedUsername: "",
  gender: "",
  pronouns: "",
  birthdate: "",
  memberType: "",
  homePhone: "",
  mobilePhone: "",
  workPhone: "",
  otherPhone: "",
  streetAddress: "",
  streetAddress2: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  initiatedDate: "",
  inductionDate: "",
  lastLoginDate: "",
  naaFounder: false,
  email: "",
  emergencyContactName: "",
  emergencyContactNumber: "",
  emergencyContactRelation: "",
  emergencyContactAware: false,
  crowdUsername: "",
  memberStatusId: 0,
  roleId: 0,
  chapterLeadershipPositionId: 0,
  hideEmail: false,
});
export const $selectedMemberId = profileDomain.store(null);
export const $selectedMemberCountryCode = profileDomain.store(null);
export const $countries = profileDomain.store([]);
export const $states = profileDomain.store([]);
export const $roles = profileDomain.store([]);

// Profile Page Events
export const setSelectedMemberId = profileDomain.event();
export const setCountryCodeFromName = profileDomain.event();
export const setSelectedMemberCountryCode = profileDomain.event();
export const submitProfileUpdates = profileDomain.event();
export const confirmInformation = profileDomain.event();

// Profile Page Effects
export const fetchCountriesFx = profileDomain.effect();
export const fetchMemberFx = profileDomain.effect();
export const fetchLoggedInUserFx = profileDomain.effect();
export const fetchRolesFx = profileDomain.effect();
export const fetchStatesFx = profileDomain.effect();
export const updateUserFx = profileDomain.effect();
export const confirmInformationFx = profileDomain.effect();

// Profile Page parameters stores
export const $isLoading = combine(
  getChaptersFx.pending,
  fetchCountriesFx.pending,
  fetchMemberFx.pending,
  fetchMemberStatusesFx.pending,
  fetchLeadershipPositionsFx.pending,
  fetchLoggedInUserFx.pending,
  fetchRolesFx.pending,
  fetchStatesFx.pending,
  userLoggedInFx.pending,
  (
    chapterListPending,
    countryPending,
    memberPending,
    statusesPending,
    positionsPending,
    getLoggedInUserPending,
    rolesPending,
    statesPending,
    userLoginPending
  ) =>
    chapterListPending ||
    countryPending ||
    memberPending ||
    statusesPending ||
    positionsPending ||
    getLoggedInUserPending ||
    rolesPending ||
    statesPending ||
    userLoginPending
);

export const $profilePageParams = combine(
  $isEditMode,
  $showDebugInfo,
  $chapters,
  $countries,
  $leadershipPositions,
  $memberStatuses,
  $roles,
  $selectedMember,
  $selectedMemberId,
  $states,
  $isLoading,
  updateUserFx.pending,
  (
    isEditMode,
    showDebugInfo,
    chapters,
    countries,
    leadershipPositions,
    statuses,
    roles,
    selectedMember,
    selectedMemberId,
    states,
    isLoading,
    isSaving
  ) => ({
    selectedMember,
    selectedMemberId,
    isEditMode,
    showDebugInfo,
    countries,
    states,
    roles,
    statuses,
    leadershipPositions,
    chapters,
    isLoading,
    isSaving,
  })
);
