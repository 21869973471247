import React from 'react';

import MaterialTable from '@material-table/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router-dom';

import { $isLoading, $mmMembers } from '../../../../effector/Members/ManageMembers';
import { setSelectedMemberId } from '../../../../effector/Members/ProfilePage';
import { membersTableColumns } from '../../Admin/member-management/constants';

export const DirectoryTable = () => {
  const members = useStore($mmMembers);
  const membersLoading = useStore($isLoading);

  const history = useHistory();

  return (
    <>
      <MaterialTable 
        columns={membersTableColumns}
        data={members}
        isLoading={membersLoading}
        options={{
          headerStyle: {
            backgroundColor: '#6993FF',
            color: '#fff',
            fontSize: '12px'
          },
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          toolbar: false
        }}
        actions={[
          {
            icon : () => (<VisibilityIcon />),
            tooltip: "View Profile",
            onClick: (_, rowData) => {
              setSelectedMemberId(rowData.memberId);
              history.push('/profile');
            },
          }
        ]}
      />
    </>
  );
}