/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import {Redirect, Switch, Route, useHistory} from "react-router-dom";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import { storeHistory } from "../effector/History";

export function Routes() {
    const isAuthenticated = useIsAuthenticated();
    const {inProgress} = useMsal();
    const doneLoading = inProgress === 'none';

    const history = useHistory();
    storeHistory(history);

    if (!doneLoading) {
        return (
            <h1>Logging in...</h1>
        );
    } 
    else {
        return (
            <Switch>
                {!isAuthenticated ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage/>
                    </Route>
                ) : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/"/>
                )}

                <Route path="/error" component={ErrorsPage}/>
                <Route path="/logout" component={Logout}/>

                {!isAuthenticated ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login"/>
                ) : (
                    <Layout>
                        <BasePage/>
                    </Layout>
                )}
            </Switch>
        );
    }


}
