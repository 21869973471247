export const labels = {
  bulkImport: "Bulk Import Members",
  addMember: "Add Member",
  filterByStatus: "Filter By Status",
  filterByChapter: "Filter By Chapter",
  filterByPosition: "Filter By Leadership Position",
  searchByName: "Search By Name"
};

export const membersTableColumns = [
  {
    field: "firstName",
    title: "First Name",
  },
  {
    field: "lastName",
    title: "Last Name",
  },
  {
    field: "currentChapterName",
    title: "Chapter",
  },
  {
    field: "memberStatusName",
    title: "Status"
  }
]

export const AddMemberModalLabels = {
  modalTitle: 'Add Member',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  role: 'Member Role',
  currentChapter: 'Current Chapter',
  memberStatus: 'Member Status',
  cancel: 'Cancel',
  saveAndAddAnother: 'Save and Add Another',
  save: 'Save'
};

export const AddMemberModalErrors = {
  firstName: 'First Name is required',
  lastName: 'Last Name is required',
  email: 'Email is required',
  role: 'Member Role is required',
  currentChapter: 'Current Chapter is required',
  memberStatus: 'Member Status is required'
}

export const ImportMembersModalLabels = {
  modalTitle: 'Import Members',
  instructions: 'Download the template file below and make sure to populate ALL columns for each member you\'d like to add',
  templateFileLink: 'Bulk Create Members Template File',
  cancel: 'Cancel',
  import: 'Import',
  save: 'Save'
};