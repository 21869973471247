import React from 'react';

import { Grid, Typography } from '@mui/material';

import { useStore } from 'effector-react';

import { $profilePageParams, setCountryCodeFromName } from '../../../../effector/Members/ProfilePage';
import { FormTextInput as Input, FormSelect as Select, FormCheckbox as Checkbox } from '../../common/components/Forms';
import { errorMessages, labels } from '../constants';
import { useStyles } from './ProfilePageStyles';

export const ContactInfoSection = ({ control, isEditMode }) => {
  const { contentContainer, inputField } = useStyles();

  const { states, countries } = useStore($profilePageParams);

  return (
    <div className={contentContainer}>
      <Typography className='mb-2' variant='h5' gutterBottom>
          {labels.contactInfoSectionHeader}
      </Typography>
      <Grid
        container
        key={`group-contactInfo`}
        direction='row'
        justify='flex-start'
        spacing={1}
        alignItems='flex-start'
      >
        <Grid item xs={8.5}>
          <Input 
            name='email'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.email}
            rules={{
              required: errorMessages.email
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={3.5}>
          <Checkbox 
            name='hideEmail'
            control={control}
            disabled={!isEditMode}
            label={labels.hideEmail}
            paddingTop="8px"
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='homePhone'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.homePhone}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='mobilePhone'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.mobilePhone}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='workPhone'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.workPhone}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='otherPhone'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.otherPhone}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='streetAddress'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.streetAddress}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='streetAddress2'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.streetAddress2}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='city'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.city}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='state'
            control={control}
            disabled={!isEditMode}
            label={labels.state}
            items={states}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='postalCode'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.postalCode}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='country'
            control={control}
            disabled={!isEditMode}
            label={labels.country}
            onChangeHandler={(data) => setCountryCodeFromName(data)}
            items={countries}
            size='small'
          />
        </Grid>
      </Grid>
    </div>
  );
}