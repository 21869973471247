import { combine, createDomain } from "effector";
import { createGate } from "effector-react";

export const ReportsDomain = createDomain('Reports');
export const ReportsGate = createGate('Reports');

export const $allMembers = ReportsDomain.store([]);
export const $reportData = ReportsDomain.store([]).reset(ReportsGate.close);
export const $selectedReport = ReportsDomain.store('').reset(ReportsGate.close);

export const setSelectedReport = ReportsDomain.event();

export const fetchAllMembersFx = ReportsDomain.effect();
export const fetchRecentAlumniFx = ReportsDomain.effect();
export const fetchChapterMemberCountFx = ReportsDomain.effect();

export const $isReportLoading = combine(
  fetchAllMembersFx.pending,
  fetchRecentAlumniFx.pending,
  fetchChapterMemberCountFx.pending,
  (isMembersLoading, isRecentAlumniLoading, isChapterMemberCountLoading) => isMembersLoading || isRecentAlumniLoading || isChapterMemberCountLoading
);