import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useStore } from 'effector-react';

import { $profilePageParams } from '../../../../effector/Members/ProfilePage';
import { FormTextInput as Input, FormSelect as Select } from '../../common/components/Forms';
import { labels } from '../constants';
import { useStyles } from './ProfilePageStyles';

export const FraternityInfoSection = ({ control, isEditMode }) => {
  const { contentContainer, inputField } = useStyles();

  const { chapters, leadershipPositions, statuses } = useStore($profilePageParams);

  return (
    <div className={contentContainer}>
      <Typography className='mb-2' variant='h5' gutterBottom>
          {labels.fraternityInfoSectionHeader}
      </Typography>
      <Grid
        container
        key={`group-contactInfo`}
        direction='row'
        justify='flex-start'
        spacing={1}
        alignItems='flex-start'
      >
        <Grid item xs={6}>
          <Select 
            name='memberStatusId'
            control={control}
            disabled={!isEditMode}
            label={labels.memberStatus}
            items={statuses}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='currentChapterId'
            control={control}
            disabled={!isEditMode}
            label={labels.chapter}
            items={chapters}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='chapterLeadershipPositionId'
            control={control}
            disabled={!isEditMode}
            label={labels.chapterPosition}
            items={leadershipPositions}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='crowdUsername'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.crowdUsername}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='initiationChapterId'
            control={control}
            disabled={!isEditMode}
            label={labels.initiatedChapter}
            items={chapters}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='initiatedDate'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.initiatedDate}
            size='small'
            type='date'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='newMemberClass'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.newMemberClass}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='dlpUnifiedUsername'
            control={control}
            className={inputField}
            disabled
            label={labels.dlpUnifiedUsername}
            size='small'
          />
        </Grid>
      </Grid>
    </div>
  );
}