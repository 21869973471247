import { createDomain } from 'effector';
import { createGate } from 'effector-react';

const UserDomain = createDomain();

// Token Handling
export const $userMSAL = UserDomain.store(null);
export const setUserMSAL = UserDomain.event();

// Login Handling
export const loginGate = createGate();
export const $loggedInUser = UserDomain.store({
  loggedInUser: null,
  needsUpdates: false
});

export const userLoggedInEvent = UserDomain.event();
export const userLoggedInFx = UserDomain.effect();