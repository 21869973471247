import { guard, sample } from "effector";

import { Severity } from "../../../app/modules/Common/Snackbar/Enums";
import { getAllMembers, getFilteredMembers, getLeadershipPositions, getMemberStatuses, resetPassword } from "../../CRUD/memberCrud";
import { fetchChapterListFx } from "../../Chapters/ChaptersPage";
import { setContents } from "../../Snackbar";
import { 
  $chapterDropdownItems, 
  $leadershipPositions, 
  $memberFilter, 
  $memberStatuses, 
  $mmMembers, 
  MemberGate, 
  fetchFilteredMembersFx, 
  fetchLeadershipPositionsFx, 
  fetchMemberStatusesFx, 
  fetchMembersFx, 
  filterMembers, 
  resetPasswordFx, 
  setMemberFilter 
} from ".";

// Set Effect Calls
fetchMembersFx.use(getAllMembers);
fetchFilteredMembersFx.use(getFilteredMembers);
fetchMemberStatusesFx.use(getMemberStatuses);
fetchLeadershipPositionsFx.use(getLeadershipPositions);
resetPasswordFx.use(resetPassword);

// Member Filter Event Handling
$memberFilter.on(setMemberFilter, (state, data) => ({
  ...state,
  ...data
}));

// Member Filter Effect Handling
$chapterDropdownItems.on(fetchChapterListFx.doneData, (_, data) =>
  data.map(chapter => ({
    value: chapter.chapterId,
    label: chapter.chapterName
  }))
);
$memberStatuses.on(fetchMemberStatusesFx.doneData, (_, data) => data);
$leadershipPositions.on(fetchLeadershipPositionsFx.doneData, (_, data) => data);

// Member Table Effect Handling
$mmMembers.on(fetchMembersFx.doneData, (_, data) => data);
$mmMembers.on(fetchFilteredMembersFx.doneData, (_, data) => data);

// Member Table and Filter Effect Triggers
sample({
  source: $memberFilter,
  clock: filterMembers,
  filter: ({ statusId, chapterId, chapterLeadershipId, searchText }) => (
    (statusId !== '' && statusId > 0) || (chapterId !== '' && chapterId > 0) ||
    (chapterLeadershipId !== '' && chapterLeadershipId > 0) || (searchText !== '' && searchText !== null)
  ),
  fn: ({ statusId, chapterId, chapterLeadershipId, searchText }) => ({
    statusId,
    chapterId,
    chapterLeadershipId,
    searchText
  }),
  target: fetchFilteredMembersFx
});

guard({
  source: $memberFilter,
  filter: ({ statusId, chapterId, chapterLeadershipId, searchText }) => (
    (statusId === '' || statusId <= 0) && (chapterId === '' || chapterId <= 0) &&
    (chapterLeadershipId === '' || chapterLeadershipId <= 0) && (searchText === '' || searchText == null)
  ),
  target: fetchMembersFx
});

sample({
    clock: [MemberGate.open],
    target: [fetchMembersFx, fetchMemberStatusesFx, fetchLeadershipPositionsFx, fetchChapterListFx]
});

sample({
  clock: resetPasswordFx.doneData,
  fn: () => ({
    message: "Password reset successfully",
    severity: Severity.Success,
  }),
  target: setContents,
});

sample({
  clock: resetPasswordFx.failData,
  fn: (failData) => ({
    message: failData.message,
    severity: Severity.Error,
  }),
  target: setContents,
});