import {LogLevel} from "@azure/msal-browser";
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        authority: 'https://login.microsoftonline.com/b4a0ecbb-ac47-4e82-b370-9e7b43698366',
        clientId: '560fe992-6157-464a-9365-5937eac3dc25',
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_MSAL_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: false,
        validateAuthority: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        allowRedirectInIframe: true,
        loadFrameTimeout: 10000,
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    // return;
                }

                // switch (level) {
                //     case LogLevel.Error:
                //         console.error(message);
                //         return;
                //     case LogLevel.Info:
                //         console.info(message);
                //         return;
                //     case LogLevel.Verbose:
                //         console.debug(message);
                //         return;
                //     case LogLevel.Warning:
                //         console.warn(message);
                //         return;
                // }
            },
            logLevel: LogLevel.Error
        }
    }
};

export const tenantId = 'b4a0ecbb-ac47-4e82-b370-9e7b43698366';

export const apiTokenRequest = (account) => {
    return {
        scopes: ['api://560fe992-6157-464a-9365-5937eac3dc25/open_id'],
            account: account
    }
};

export const loginRequest = {
    scopes: [
        'api://560fe992-6157-464a-9365-5937eac3dc25/open_id',
    ]
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const graphRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
