import React from 'react';

import { Grid, Typography } from "@mui/material";

import { FormTextInput as Input, FormSelect as Select } from '../../common/components/Forms';
import { genderOptions, errorMessages, labels } from '../constants';
import { useStyles } from './ProfilePageStyles';

export const PersonalInfoSection = ({ control, isEditMode }) => {
  const { contentContainer, inputField } = useStyles();

  return (
    <div className={contentContainer}>
      <Typography className='mb-2' variant='h5' gutterBottom>
          {labels.personalInfoSectionHeader}
      </Typography>
      <Grid
        container
        key={`group-personalInfo`}
        direction='row'
        justify='flex-start'
        spacing={1}
        alignItems='flex-start'
      >
        <Grid item xs={6}>
          <Input 
            name='firstName'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.firstName}
            rules={{
              required: errorMessages.firstName
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='lastName'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.lastName}
            rules={{
              required: errorMessages.lastName
            }}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Select 
            name='gender'
            control={control}
            disabled={!isEditMode}
            label={labels.gender}
            items={genderOptions}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='pronouns'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.pronouns}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='birthdate'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.birthDate}
            type='date'
            size='small'
          />
        </Grid>       
      </Grid>
    </div>
  );
}