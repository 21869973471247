export const UserRoles = {
  Admin: 1,
  ChapterSupport: 2,
  Office: 3,
  Executive: 4,
  User: 5
};

export const MemberStatuses = {
  Active: 1,
  NewMember: 2,
  Alumnus: 3,
  Expelled: 4,
  Associate: 5,
  Disassociated: 6,
  Honorary: 7,
  Dropped: 8,
  Unknown: 9
};