import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import React from 'react';

export const FilterSelect = ({ label, value, disabled, items, onChange, name }) => {
  return (
    <>
      <FormControl
        margin={'dense'}
        variant="outlined"
        disabled={disabled}
        fullWidth
      >
        <Select
          id={`filter-select-${name}`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          <MenuItem value={0}><br/></MenuItem>
          {items.map(({ value, label }) => (
            <MenuItem 
              key={`filter-select-${name}-${value}`} 
              value={value}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{label}</FormHelperText>
      </FormControl>
    </>
  )
};