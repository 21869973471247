import { createApi, createDomain } from "effector";

export const snackbarDomain = createDomain("Snackbar");

export const $isSnackbarOpen = snackbarDomain.store(false);
export const snackbarApi = createApi($isSnackbarOpen, {
  show: () => true,
  hide: () => false,
});

export const $alertContents = snackbarDomain
  .store({
    message: "",
    severity: "",
  })
  .reset(snackbarApi.hide);

export const setContents = snackbarDomain.event();
