/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useStore } from "effector-react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import { $loggedInUser } from "../../../../../effector/User";
import { checkIsActive } from "../../../../_helpers";
import { UserRoles } from "../../../../../app/Enums";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();

    const { loggedInUser } = useStore($loggedInUser);
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    const canSeeAdmin = loggedInUser && (loggedInUser.roleId === UserRoles.Admin || loggedInUser.roleId === UserRoles.ChapterSupport);

    const isFOMemberOrHigher = canSeeAdmin || (loggedInUser && loggedInUser.roleId === UserRoles.Office);

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/home')}`}>
                <NavLink className="menu-link" to="/home">
                    <span className="menu-text">Home</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/chapters')}`}>
                <NavLink className="menu-link" to="/chapters">
                    <span className="menu-text">Chapters</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/directory')}`}>
              <NavLink className="menu-link" to="/directory">
                <span className="menu-text">Member Directory</span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {isFOMemberOrHigher &&
              <li className={`menu-item menu-item-rel ${getMenuItemActive('/reports')}`}>
                <NavLink className="menu-link" to="/reports">
                  <span className="menu-text">Reports</span>
                  {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
              </li>
            }
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            {canSeeAdmin &&
            <li
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin')}`}
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
            >

                <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
                    <span className="menu-text">Admin</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/admin/chapter-management')}`}>
                            <NavLink className="menu-link" to="/admin/chapter-management">
                                <span className="menu-text">Manage Chapters</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li className={`menu-item ${getMenuItemActive('/admin/member-management')}`}>
                            <NavLink className="menu-link" to="/admin/member-management">
                                <span className="menu-text">Manage Members</span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            }
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
