import { forward, sample } from "effector";

import { getChapterList } from "../../CRUD/chapterCrud";
import { addNewMember, getMemberRoles, getMemberStatuses } from "../../CRUD/memberCrud";
import { setSelectedMemberId } from "../ProfilePage";
import { $addMultiple, $chapters, $memberRoles, $memberStatuses, $modalError, addMemberModalApi, addNewMemberEvent, addNewMemberFx, getChaptersFx, getRolesFx, getStatusesFx, setAddMultiple } from ".";

// Set Effect Calls
addNewMemberFx.use(addNewMember);
getChaptersFx.use(getChapterList);
getRolesFx.use(getMemberRoles);
getStatusesFx.use(getMemberStatuses);

// Add Member Modal Effect Handling
$chapters.on(getChaptersFx.doneData, (_, data) => data.map(chapter => ({
  value: chapter.chapterId,
  label: chapter.chapterName
})));

$memberRoles.on(getRolesFx.doneData, (_, data) => data);
$memberStatuses.on(getStatusesFx.doneData, (_, data) => data);
$addMultiple.on(setAddMultiple, (_, data) => data);

// Add Member Modal Error Handling
$modalError.on(addNewMemberFx.fail, () => "There was an issue adding this member. If the issue persists, please contact support.");

// Add Member Modal Effect Triggers
sample({
  clock: addNewMemberEvent,
  fn: (data) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    roleId: data.role,
    email: data.email,
    currentChapterId: data.currentChapter,
    memberStatusId: data.currentStatus
  }),
  target: addNewMemberFx
});

sample({
  clock: addNewMemberFx.doneData,
  source: $addMultiple,
  filter: (addMultiple) => !addMultiple,
  fn: (_, {data}) => data,
  target: setSelectedMemberId
});

forward({
  from: addMemberModalApi.open,
  to: [getChaptersFx, getRolesFx, getStatusesFx]
});
