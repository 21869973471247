/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
    MetronicLayoutProvider,
    MetronicSplashScreenProvider,
    MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";

// MSAL
import {msalConfig} from './app/modules/Auth/msalConfig';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import setupAxios from "./utils";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */
// _redux.mockAxios(axios);

/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
const instance = new PublicClientApplication(msalConfig);
setupAxios(axios, instance);

const Site = () => {
    
    return (
        <MsalProvider instance={instance}>
            <MetronicI18nProvider>
                <MetronicLayoutProvider>
                    <MetronicSubheaderProvider>
                        <MetronicSplashScreenProvider>
                            <App basename={PUBLIC_URL}/>
                        </MetronicSplashScreenProvider>
                    </MetronicSubheaderProvider>
                </MetronicLayoutProvider>
            </MetronicI18nProvider>
        </MsalProvider>
    );
}

ReactDOM.render(
    <Site/>,
    document.getElementById("root")
);
