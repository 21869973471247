import { guard, sample } from "effector";

import { 
  activateChapter, 
  deactivateChapter, 
  getAllChapters, 
  getFilteredChapters 
} from "../../CRUD/chapterCrud";
import {
  addNewChapterFx,
  fetchChapterRegionsFx,
  updateChapterFx
} from "../AddEditChapter";
import { 
  $chapterManagementFilter,
  $cmChapters,
  activateChapterFx,
  ChapterManagementGate,
  deactivateChapterFx, 
  fetchChaptersFx, 
  fetchFilteredChaptersFx,
  setChapterFilterParams
} from ".";

// Set Effect Calls
fetchChaptersFx.use(getAllChapters);
fetchFilteredChaptersFx.use(getFilteredChapters);
activateChapterFx.use(activateChapter);
deactivateChapterFx.use(deactivateChapter);

// Chapter Management Event Handling
$chapterManagementFilter.on(setChapterFilterParams, (state, data) => ({
    ...state,
    ...data
}));

// Chapter Management Effect Handling
$cmChapters.on(fetchChaptersFx.doneData, (_, data) => data);
$cmChapters.on(fetchFilteredChaptersFx.doneData, (_, data) => data);

// Chapter Management Effect Triggers
guard({
  source: $chapterManagementFilter,
  clock: [$chapterManagementFilter, activateChapterFx.done, deactivateChapterFx.done, addNewChapterFx.done, updateChapterFx.done],
  filter: ({regionId, searchText, activeOnly }) => 
    (regionId !== '' && regionId > 0) || (searchText !== '' && searchText !== null) || activeOnly,
  fn: ({ regionId, searchText, activeOnly }) => ({
    regionId: regionId === '' ? 0 : regionId,
    searchText,
    activeOnly
  }),
  target: fetchFilteredChaptersFx
});

guard({
  source: $chapterManagementFilter,
  clock: [$chapterManagementFilter, activateChapterFx.done, deactivateChapterFx.done, addNewChapterFx.done, updateChapterFx.done],
  filter: ({regionId, searchText, activeOnly }) => 
    (regionId === '' || regionId === 0) && (searchText === '' || searchText === null) && !activeOnly,
  target: fetchChaptersFx
});

sample({
    clock: [ChapterManagementGate.open],
    target: [fetchChaptersFx, fetchChapterRegionsFx]
});