import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles(() => ({
    header: {
        padding: 20,
        paddingBottom: 0,
        marginBottom: 0,
        textAlign: 'center'
    },
    contentContainer: {
        paddingBottom: 40
    },
    inputField: {
        marginRight: 20,
        width: '100%',
        flex: 'auto'
    },
    selectInputFix: {
        backgroundColor: '#fff',
        paddingLeft: 5,
        paddingRight: 5,
    },
    button: {
        minWidth: '200px',
        marginLeft: '5px',
        marginRight: '5px'
    }
}));