import axios from "axios";

const ACTIVATE_CHAPTER = "/chapter/activate/";
const ADD_NEW_CHAPTER = "/chapter/add";
const DEACTIVATE_CHAPTER = "/chapter/deactivate/";
const GET_ALL_CHAPTERS = "/chapter/all";
const GET_FILTERED_CHAPTERS = "/chapter/filter";
const GET_CHAPTER_LIST = "/chapter/list";
const GET_CHAPTER_MEMBER_COUNT = "/chapter/membercount";
const GET_REGIONS = "/chapter/regions";
const GET_TYPES = "/chapter/types";
const UPDATE_CHAPTER = "/chapter/update";

export function activateChapter(chapterId) {
  return axios.put(ACTIVATE_CHAPTER + chapterId);
}

export function deactivateChapter(chapterId) {
  return axios.put(DEACTIVATE_CHAPTER + chapterId);
}

export function getAllChapters() {
  return axios.get(GET_ALL_CHAPTERS).then(({data}) => data);
}

export function getFilteredChapters({ regionId, searchText, activeOnly }) {
  var params = `?regionId=${regionId}&chapterName=${searchText}&showActiveOnly=${activeOnly}`;
  return axios.get(GET_FILTERED_CHAPTERS + params).then(({data}) => data);
}

export function getChapterList() {
  return axios.get(GET_CHAPTER_LIST).then(({data}) => data);
}

export function getChapterMemberCount() {
  return axios.get(GET_CHAPTER_MEMBER_COUNT).then(({data}) => data);
}

export function getChapterMemberList() {
  return axios.get()
}

export async function getChapterRegions() {
  return axios.get(GET_REGIONS).then(({data}) => data);
}

export async function getChapterTypes() {
  return axios.get(GET_TYPES).then(({data}) => data);
}

export function addNewChapter(data) {
  return axios.post(ADD_NEW_CHAPTER, data);
}

export function updateChapter(data) {
  return axios.put(UPDATE_CHAPTER, data);
}