import { apiTokenRequest } from "./app/modules/Auth/msalConfig";

export default function setupAxios(axios, instance) {
  axios.interceptors.request.use(
    async config => {
      var account = instance.getAllAccounts()[0];
  
      var token = await instance.acquireTokenSilent(apiTokenRequest(account));
      
      config.headers.common.Authorization = `Bearer ${token.accessToken}`;
  
      config.url = process.env.REACT_APP_BASE_URL + config.url;
      return config;
    },
    err => Promise.reject(err)
  );
}