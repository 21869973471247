import React from "react";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { MembersFilter } from "../Admin/member-management/components/MembersFilter";
import { DirectoryTable } from "./components/DirectoryTable";
import { useGate } from "effector-react";
import { MemberGate } from "../../../effector/Members/ManageMembers";

export const MemberDirectoryPage = () => {
  useGate(MemberGate);

  return (
    <Card>
      <CardHeader title="Directory List"/>
      <CardBody>
        <MembersFilter />
        <DirectoryTable />
      </CardBody>
    </Card>
  );
};
