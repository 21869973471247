import axios from "axios";

const ADD_NEW_MEMBER = "/member/registerNew";
const BULK_CREATE_MEMBERS = "/member/registerBulk";
const CONFIRM_INFO = "/member/confirm";
const GET_ALL_MEMBERS = "/member/all";
const GET_BY_CHAPTER = "/member/chapter/";
const GET_FILTERED_MEMBERS = "/member/filter";
const GET_LEADERSHIP_POSITIONS = "/member/positions";
const GET_MEMBER = "/member/";
const GET_MEMBER_STATUSES = "/member/statuses";
const GET_MEMBER_REPORTS = "/member/reports";
const GET_MEMBER_ROLES = "/member/roles";
const GET_NEW_CHAPTER_MEMBERS = "/member/newmember/";
const GET_RECENT_ALUMNI = "/member/recentalumni";
const RESET_PASSWORD = "/member/password/";
const UPDATE_MEMBER = "/member/";

export function addNewMember(data) {
  return axios.post(ADD_NEW_MEMBER, data);
}

export function bulkCreateMembers({ token, data }) {
  const formData = new FormData();
  formData.append("bulkCreateFile", data || "");

  return axios.post(BULK_CREATE_MEMBERS, formData, {
    authToken: token,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function confirmMemberInformation() {
  return axios.put(CONFIRM_INFO);
}

export function getAllMembers() {
  return axios.get(GET_ALL_MEMBERS).then(({ data }) => data);
}

export function getByChapter({ chapterId }) {
  var params = `${chapterId}`;
  return axios.get(GET_BY_CHAPTER + params).then(({ data }) => data);
}

export function getFilteredMembers({
  statusId,
  chapterId,
  chapterLeadershipId,
  searchText,
}) {
  var params = `?memberStatusId=${statusId}&chapterId=${chapterId}&leadershipPositionId=${chapterLeadershipId}&searchText=${searchText}`;
  return axios.get(GET_FILTERED_MEMBERS + params).then(({ data }) => data);
}

export function getLeadershipPositions() {
  return axios.get(GET_LEADERSHIP_POSITIONS).then(({ data }) => data);
}

export function getLoggedInUser() {
  return axios.get(GET_MEMBER).then(({ data }) => data);
}

export function getNewChapterMembers({ chapterId }) {
  var params = `${chapterId}`;
  return axios.get(GET_NEW_CHAPTER_MEMBERS + params).then(({data}) => data);
}

export function getMemberById(id) {
  var params = `id/${id}`;
  return axios.get(GET_MEMBER + params).then(({ data }) => data);
}

export function getMemberStatuses() {
  return axios.get(GET_MEMBER_STATUSES).then(({ data }) => data);
}

export function getMemberReports() {
  return axios.get(GET_MEMBER_REPORTS).then(({data}) => data);
}

export function getMemberRoles() {
  return axios.get(GET_MEMBER_ROLES).then(({ data }) => data);
}

export function getRecentAlumni() {
  return axios.get(GET_RECENT_ALUMNI).then(({ data }) => data);
}

export function resetPassword(memberId) {
  return axios.put(RESET_PASSWORD + memberId);
}

export function updateMember(data) {
  return axios.put(UPDATE_MEMBER, data);
}
