import React from 'react';

import { TextField } from '@mui/material';

export const FilterInput = ({label, disabled, value, onChange, name}) => {
  return (
    <TextField
        id={`text-input-${label}`}
        helperText={label}
        name={name}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        value={value}
        type="text"
        variant="outlined"
        margin="dense"
        fullWidth
    />
  );
}