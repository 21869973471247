import { forward, sample } from "effector";

import { 
  addNewChapter, 
  getChapterRegions, 
  getChapterTypes, 
  updateChapter 
} from "../../CRUD/chapterCrud";
import {
  $chapterRegions,
  $chapterTypes,
  $modalChapter,
  $modalError,
  addChapter,
  addChapterModalApi,
  addNewChapterFx,
  editChapterModalApi,
  fetchChapterRegionsFx,
  fetchChapterTypesFx,
  setSelectedChapterToUpdate,
  updateChapterFx,
  updateSelectedChapter
} from "."

// Set Effect Calls
addNewChapterFx.use(addNewChapter);
fetchChapterRegionsFx.use(getChapterRegions);
fetchChapterTypesFx.use(getChapterTypes);
updateChapterFx.use(updateChapter);

// Add/Edit Chapter Modal Event Handling
$modalChapter.on(setSelectedChapterToUpdate, (state, data) => ({
  ...state,
  ...data
}));

// Add/Edit Chapter Modal Effect Handling
$chapterRegions.on(fetchChapterRegionsFx.doneData, (_, data) => data);
$chapterTypes.on(fetchChapterTypesFx.doneData, (_, data) => data);

// Add/Edit Chapter Modal Error Handling
$modalError.on(addNewChapterFx.fail, () => "There was an issue adding this chapter. If the issue persists, please contact support.");
$modalError.on(updateChapterFx.fail, () => "There was an issue updating this chapter. If the issue persists, please contact support.");

// Add/Edit Chapter Modal Effect Triggers
sample({
  clock: addChapter,
  fn: (data) => ({
    chapterName: data.chapterName,
    chapterLocation: data.location,
    university: data.university,
    chapterTypeId: data.chapterTypeId,
    chapterRegionId: data.chapterRegionId,
    isActive: data.isActive
  }),
  target: addNewChapterFx
});

sample({
  clock: updateSelectedChapter,
  fn: (data) => ({
    chapterId: data.chapterId,
    chapterName: data.chapterName,
    chapterLocation: data.location,
    university: data.university,
    chapterTypeId: data.chapterTypeId,
    chapterRegionId: data.chapterRegionId,
    isActive: data.isActive
  }),
  target: updateChapterFx
});

forward({
  from: [addChapterModalApi.open, editChapterModalApi.open],
  to: [fetchChapterRegionsFx, fetchChapterTypesFx]
});

// Add/Edit Chapter Modal Close Handling
forward({
    from: addNewChapterFx.done,
    to: addChapterModalApi.close
});

forward({
    from: updateChapterFx.done,
    to: editChapterModalApi.close
});

