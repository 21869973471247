import MaterialTable from '@material-table/core';
import { useGate, useStore } from 'effector-react';
import React from 'react';
import { $newChapterMembers, NewMemberGate } from '../../../../effector/Dashboard';
import { NewMembersTableColumns, NewMembersTableTitle } from '../constants';
import { fetchChapterMembersFx } from '../../../../effector/Chapters/ChaptersPage';
import { setSelectedMemberId } from '../../../../effector/Members/ProfilePage';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const NewMembersTable = () => {
  useGate(NewMemberGate);
  const newMembers = useStore($newChapterMembers);
  const isLoading = useStore(fetchChapterMembersFx.pending);

  return (
    <MaterialTable 
      data={newMembers}
      columns={NewMembersTableColumns}
      isLoading={isLoading}
      title={NewMembersTableTitle}
      options={{
        headerStyle: {
          backgroundColor: '#6993FF',
          color: '#fff',
          fontSize: '12px'
        },
      }}
      actions={[
        {
          icon : () => (<VisibilityIcon />),
          tooltip: "View Profile",
          onClick: (_, rowData) => {
            setSelectedMemberId(rowData.memberId);
          },
        },
      ]}
    />
  );
}