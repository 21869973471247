import { combine, createApi } from "effector";

import { membersDomain } from '../ManageMembers'

// Add Member Modal Open State/Handler
export const $isAddMemberModalOpen = membersDomain.store(false);
export const addMemberModalApi = createApi($isAddMemberModalOpen, {
  open: () => true,
  close: () => false,
});

// Add Member Modal Stores
export const $newMember = membersDomain
  .store({
    firstName: "",
    lastName: "",
    role: "5",
    email: "",
    currentChapter: "",
    currentStatus: "1"
  })
  .reset(addMemberModalApi.close);
export const $chapters = membersDomain.store([]);
export const $memberRoles = membersDomain.store([]);
export const $memberStatuses = membersDomain.store([]);
export const $addMultiple = membersDomain.store(false);

// Add Member Modal Events
export const addNewMemberEvent = membersDomain.event();
export const setAddMultiple = membersDomain.event();

// Add Member Modal Effects
export const addNewMemberFx = membersDomain.effect();
export const getChaptersFx = membersDomain.effect();
export const getRolesFx = membersDomain.effect();
export const getStatusesFx = membersDomain.effect();

// Error Handling Store
export const $modalError = membersDomain.store("").reset([addMemberModalApi.close, addNewMemberFx]);

// Modal Params Store
export const $newMemberModalParams = combine(
  $newMember,
  $chapters,
  $memberRoles,
  $memberStatuses,
  $modalError,
  (newMember, chapters, roles, statuses, error) => ({
    newMember,
    chapters,
    roles,
    statuses,
    error
  })
);