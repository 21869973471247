import { sample } from 'effector';

import { getChapterList } from '../../CRUD/chapterCrud';
import { getByChapter } from '../../CRUD/memberCrud';
import {
  fetchChapterListFx, 
  $selectedChapter, 
  setSelectedChapter, 
  ChaptersPageGate, 
  $showActiveChaptersOnly, 
  setShowActiveOnly, 
  setFilterText, 
  $filteredChapters, 
  $chapterList, 
  $filterText, 
  $selectedChapterIndex, 
  setSelectedChapterIndex, 
  setFilteredChapters, 
  fetchChapterMembersFx, 
  $chapterLeadership,
  $chapterActiveMembers,
  $chapterAlumniMembers,
  $chapterOtherMembers
} from '.';


// Set Effect Calls
fetchChapterListFx.use(getChapterList);
fetchChapterMembersFx.use(getByChapter);

// Chapter Page Event Handling
$selectedChapter.on(setSelectedChapter, (_, data) => data);
$selectedChapter.on(setFilteredChapters, (_, data) => data[0]);
$showActiveChaptersOnly.on(setShowActiveOnly, (_, data) => data);
$selectedChapterIndex.on(setSelectedChapterIndex, (_, data) => data);
$selectedChapterIndex.on(setFilteredChapters, () => 0);
$filterText.on(setFilterText, (_, data) => data);
$filteredChapters.on(setFilteredChapters, (_, data) => data);
$chapterLeadership.on(fetchChapterMembersFx.doneData, (_, data) => 
  data.filter(member => member.chapterLeadershipPosition !== null));
$chapterActiveMembers.on(fetchChapterMembersFx.doneData, (_, data) => 
  data.filter(member => member.memberStatusName === "Active" || member.memberStatusName === "New Member"));
$chapterAlumniMembers.on(fetchChapterMembersFx.doneData, (_, data) => 
  data.filter(member => member.memberStatusName === "Alumnus"));
$chapterOtherMembers.on(fetchChapterMembersFx.doneData, (_, data) => 
  data.filter(member => member.memberStatusName !== "Active" && member.memberStatusName !== "New Member" 
    && member.memberStatusName !== "Alumnus" && member.memberStatusName !== "Expelled"));


// Chapter Page Effect Handling
$chapterList.on(fetchChapterListFx.doneData, (_, data) => data);

// Chapter Page Trigger Handling
sample({
  clock: [ChaptersPageGate.open],
  target: fetchChapterListFx
});

sample({
  clock: fetchChapterListFx.doneData,
  source: {filter: $filterText, activeOnly: $showActiveChaptersOnly},
  fn: ({filter, activeOnly}, chapters) => {
    var chapterList = chapters;
    if (filter && filter !== "") {
      chapterList = chapters.filter((chapter) =>
        chapter.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
      );
    }

    if (activeOnly) {
      chapterList = chapterList.filter((chapter) => 
        chapter.isActive === true
      );
    }
    
    return chapterList;
  },
  target: setFilteredChapters
});

sample({
  clock: setFilterText,
  source: {chapters: $chapterList, activeOnly: $showActiveChaptersOnly},
  fn: ({chapters, activeOnly}, filterText) => {
    var chapterList = chapters;

    if (filterText && filterText !== "") {
      chapterList = chapterList.filter((chapter) =>
        chapter.chapterName.toLowerCase().indexOf(filterText.toLowerCase()) > -1
      );
    }

    if (activeOnly){
      chapterList = chapterList.filter((chapter) => 
        chapter.isActive === true
      );
    }
    
    return chapterList;
  },
  target: setFilteredChapters
});

sample({
  clock: setShowActiveOnly,
  source: {chapters: $chapterList, filterText: $filterText},
  fn: ({chapters, filterText}, activeOnly) => {
    var chapterList = chapters;

    if (filterText && filterText !== "") {
      chapterList = chapterList.filter((chapter) =>
        chapter.chapterName.toLowerCase().indexOf(filterText.toLowerCase()) > -1
      );
    }

    if (activeOnly){
      chapterList = chapterList.filter((chapter) => 
        chapter.isActive === true
      );
    }
    
    return chapterList;
  },
  target: setFilteredChapters
});

sample({
  clock: $selectedChapter,
  fn: (chapter) => ({
    chapterId: chapter.chapterId
  }),
  target: fetchChapterMembersFx
});