import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({control, disabled, label, name, paddingTop}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: {onChange, value} }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              disabled={disabled}
              onChange={onChange}
            />
          }
          label={label}
          labelPlacement="start"
          style={{ paddingTop }}  
        />                     
      )}
    />
  );
}