import React from 'react';

import { Paper, Select, MenuItem, ListSubheader, FormControl, InputLabel } from "@mui/material";
import { useGate, useStore } from 'effector-react';

import { $selectedReport, ReportsGate, setSelectedReport } from '../../../effector/Reports';
import { ChapterSupportReports, DropdownLabel, MarketingReports, MenuSubheaderLabels } from "./constants";
import ReportsTable from './ReportsTable';

const ReportsPage = () => {
  useGate(ReportsGate);
  const selectedReport = useStore($selectedReport);

  return (
    <Paper className="p-4">
      <div className="card card-custom gutter-b example">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Reports</h3>
          </div>
        </div>
        <div className="card-body">
          <FormControl fullWidth>
            <InputLabel id="selected-report-label">{DropdownLabel}</InputLabel>
            <Select
              labelId="selected-report-label"
              id="selected-report"
              label={DropdownLabel}
              onChange={(event) => setSelectedReport(event.target.value)}
              value={selectedReport}
            >
              <ListSubheader>{MenuSubheaderLabels.chapterSupport}</ListSubheader>
              {ChapterSupportReports.map(({value, label}) => 
                <MenuItem key={`${value}-rpt-option`} value={value}>{label}</MenuItem>
              )}
              <ListSubheader>{MenuSubheaderLabels.marketing}</ListSubheader>                 
              {MarketingReports.map(({value, label}) => 
                <MenuItem key={`${value}-rpt-option`} value={value}>{label}</MenuItem>  
              )}
            </Select>     
          </FormControl>   
        </div>
      </div>
      <ReportsTable />
    </Paper>    
  );
};

export default ReportsPage;