export const filterLabels = {
  chapterName: 'Chapter Name',
  activeOnly: 'Active Chapters Only',
  title: 'Filters'
};

export const chapterInfoLabels = {
  university: 'University:',
  city: 'City:',
  region: 'Region:',
  status: 'Status'
};

export const chapterMemberListLabels = {
  chapterLeadership: 'Chapter Leadership',
  activeMembers: 'Active Members',
  alumniMembers: 'Alumnus',
  otherMembers: 'Other Members'
};

export const UserStatusCssClasses = {
  'Active': 'success',
  'Alumnus': 'info',
  'Other': 'dark',
  'Disassociated': 'warning',
  'New Member': 'primary',
  'Honorary': 'info',
  'Associate': 'primary',
  'Expelled': 'warning',
  'Dropped': 'warning',
  'Unknown': 'danger',
};