import React from 'react';

import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormTextInput = ({ 
  name, 
  className, 
  control, 
  disabled,
  fullWidth,
  label, 
  rules,
  size="medium",
  style, 
  type = "text" 
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{...rules}}
      render={({
        field: { onChange, value },
        fieldState: {error},
      }) => (
        <TextField
          id={`input-${label}`}
          autoComplete='off'
          helperText={error?.message}
          disabled={disabled}
          error={!!error}
          className={className || undefined}
          onChange={onChange}
          fullWidth={fullWidth}
          value={value}
          label={label}
          style={{...style}}
          type={type}
          InputLabelProps={{shrink: true}}
          variant="outlined"
          margin="dense"
          size={size}
        />
      )}
    />
  );
}
