import {makeStyles} from "@mui/styles";

const listStyle = {
    width: '100%',
    height: "100%",
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: 10
    },
    '&::-webkit-scrollbar-track-piece': {
        background: '#EFEFEF'
    },
    '&::-webkit-scrollbar-thumb:vertical, &::-webkit-scrollbar-thumb:horizontal': {
        background: '#3f4254',
        height: 30,
        borderRadius: 20
    },
    otherUserNameText: {
        maxWidth: 400
    }
};

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    checkPad: {
        marginRight: theme.spacing(1)
    },
    filters: {
        display: "flex",
        flexDirection: "column"
    },
    chapterPage: {
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: 10
        },
        '&::-webkit-scrollbar-track-piece': {
            background: '#EFEFEF'
        },
        '&::-webkit-scrollbar-thumb:vertical, &::-webkit-scrollbar-thumb:horizontal': {
            background: '#3f4254',
            height: 30,
            borderRadius: 20
        },
        padding: theme.spacing(3),
    },
    listItem: {
        width: '100%'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    wrappingPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    sideListStyle: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 10
        },
        '&::-webkit-scrollbar-track-piece': {
            background: '#EFEFEF'
        },
        '&::-webkit-scrollbar-thumb:vertical, &::-webkit-scrollbar-thumb:horizontal': {
            background: '#3f4254',
            height: 30,
            borderRadius: 20
        },
        height: 775
    },
    smallListStyle: {
        maxHeight: 225,
        ...listStyle
    },
    tallListStyle: {
        maxHeight: 500,
        ...listStyle
    }
}));