export const ButtonLabels = {
  cancel: "Cancel",
  save: "Save Changes",
  edit: "Edit User Information",
  resetPassword: "Reset User Password",
  showDebug: "Show Debug Info",
  hideDebug: "Hide Debug Info",
  confirmInfo: "Confirm Information",
};

export const labels = {
  firstName: "First Name",
  lastName: "Last Name",
  gender: "Gender",
  pronouns: "Pronouns",
  birthDate: "Birth Date",
  email: "Email",
  hideEmail: "Hide E-mail",
  homePhone: "Home Phone",
  mobilePhone: "Mobile Phone",
  workPhone: "Work Phone",
  otherPhone: "Other Phone",
  streetAddress: "Street Address",
  streetAddress2: "Apt #",
  city: "City",
  state: "State",
  postalCode: "Postal Code",
  country: "Country",
  memberStatus: "Current Status",
  chapter: "Chapter",
  chapterPosition: "Chapter Position",
  crowdUsername: "Crowd Username",
  initiatedChapter: "Initated Chapter",
  initiatedDate: "Initated Date",
  newMemberClass: "New Member Class",
  dlpUnifiedUsername: "DLP Unified Username",
  emergencyContactName: "Emergency Contact Name",
  emergencyContactNumber: "Emergency Contact Number",
  emergencyContactRelation: "Emergency Contact Relation",
  emergencyContactAware: "Contact Aware?",
  naaFounder: "NAA Founder",
  lastLoginDate: "Last Login Date",
  createdDate: "Created Date",
  role: "Role",
  lastUpdated: "Last Updated",
  personalInfoSectionHeader: "Personal Info",
  contactInfoSectionHeader: "Contact Info",
  fraternityInfoSectionHeader: "Fraternity Info",
  emergencyContactInfoSectionHeader: "Emergency Contact",
  adminInfoSectionHeader: "Admin Data",
};

export const errorMessages = {
  firstName: "First Name is required",
  lastName: "Last Name is required",
  email: "Email is required",
};

export const genderOptions = [
  {
    value: "AGENDER",
    label: "Agender",
  },
  {
    value: "GENDERQUEER",
    label: "Genderqueer",
  },
  {
    value: "GENDERFLUID",
    label: "Genderfluid",
  },
  {
    value: "MAN",
    label: "Man",
  },
  {
    value: "NON_BINARY",
    label: "Non-Binary",
  },
  {
    value: "QUESTIONING_UNSURE",
    label: "Questioning or Unsure",
  },
  {
    value: "TRANSGENDER",
    label: "Transgender",
  },
  {
    value: "TRANS_MAN",
    label: "Trans Man",
  },
  {
    value: "TRANS_WOMAN",
    label: "Trans Woman",
  },
  {
    value: "WOMAN",
    label: "Woman",
  },
  {
    value: "UNDISCLOSED",
    label: "Prefer Not To Say",
  },
];
