import React from 'react';

import { Grid, Typography } from '@mui/material';

import { FormCheckbox as Checkbox, FormTextInput as Input } from '../../common/components/Forms';
import { labels } from '../constants';
import { useStyles } from './ProfilePageStyles';

export const EmergencyContactInfoSection = ({ control, isEditMode }) => {
  const { contentContainer, inputField } = useStyles();

  return (
    <div className={contentContainer}>
      <Typography className='mb-2' variant='h5' gutterBottom>
          {labels.emergencyContactInfoSectionHeader}
      </Typography>
      <Grid
        container
        key={`group-contactInfo`}
        direction='row'
        justify='flex-start'
        spacing={1}
        alignItems='flex-start'
      >
        <Grid item xs={6}>
          <Input 
            name='emergencyContactName'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.emergencyContactName}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='emergencyContactNumber'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.emergencyContactNumber}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Input 
            name='emergencyContactRelation'
            control={control}
            className={inputField}
            disabled={!isEditMode}
            label={labels.emergencyContactRelation}
            size='small'
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox 
            name='emergencyContactAware'
            control={control}
            disabled={!isEditMode}
            label={labels.emergencyContactAware}
            paddingTop="8px"
          />
        </Grid>
      </Grid>
    </div>
  );
}