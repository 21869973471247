import { createDomain } from "effector";
import { createGate } from "effector-react";

export const chapterManagementDomain = createDomain();

export const ChapterManagementGate = createGate('Chapter Management');

// Chapter Management Stores
export const $chapterManagementFilter = chapterManagementDomain.store({
    regionId: 0,
    searchText: "",
    activeOnly: false
});
export const $cmChapters = chapterManagementDomain.store([]);

// Chapter Management Events
export const setChapterFilterParams = chapterManagementDomain.event();

// Chapter Management Effects
export const activateChapterFx = chapterManagementDomain.effect();
export const deactivateChapterFx = chapterManagementDomain.effect();
export const fetchChaptersFx = chapterManagementDomain.effect();
export const fetchFilteredChaptersFx = chapterManagementDomain.effect();