import React from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormSelect = ({name, control, disabled, label, items, onChangeHandler, rules, size = "medium"}) => {

  return (
    <Controller 
      name={name}
      control={control}
      rules={{...rules}}
      render={({
        field: { onChange, value },
        fieldState: {error},
      }) => (
        <FormControl
          error={error ? true : false}
          margin='dense'
          variant='outlined'
          disabled={disabled}
          fullWidth
          size={size}
        >
          <InputLabel 
            id={`select-${label}-label`}
            htmlFor={`select-${label}`}
          >
            {label}
          </InputLabel>
          <Select
            labelId={`select-${label}-label`}
            id={`select-${label}`}
            value={value}
            label={label}
            onChange={(event) => {
              if (onChangeHandler != null) {
                onChangeHandler(event.target.value);
                onChange(event.target.value);
              }
              else {
                onChange(event.target.value);
              }
            }}
          >
          <MenuItem
            value={0}
            key={`select-${label}-menuitem-null`}
          ><br/></MenuItem>
          {items.map(item => (
            <MenuItem 
              value={item.value} 
              key={`select-${label}-menuitem-${item.value}`}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText>
            {error?.message}
          </FormHelperText>
        )}
      </FormControl>
      )}
    />
  );
}