import { combine, createDomain } from "effector";
import { createGate } from "effector-react";

export const membersDomain = createDomain("Members")
export const MemberGate = createGate('Members');

// Member Filter Stores
export const $memberFilter = membersDomain.store({
  statusId: 0,
  chapterId: 0,
  chapterLeadershipId: 0,
  searchText: ""
}).reset(MemberGate.close);
export const $chapterDropdownItems = membersDomain.store([]);
export const $memberStatuses = membersDomain.store([]);
export const $leadershipPositions = membersDomain.store([]);

export const $memberFilterParams = combine(
  $memberFilter,
  $memberStatuses,
  $leadershipPositions,
  $chapterDropdownItems,
  (memberFilter, statuses, positions, chapters) => ({
    memberFilter,
    statuses,
    positions,
    chapters
  })
);

// Member Filter Events
export const setMemberFilter = membersDomain.event();
export const filterMembers = membersDomain.event();

// Member Filter Effects
export const fetchMemberStatusesFx = membersDomain.effect();
export const fetchLeadershipPositionsFx = membersDomain.effect();

// Members Table Stores
export const $mmMembers = membersDomain.store([]);

// Members Table Effects
export const fetchMembersFx = membersDomain.effect();
export const fetchFilteredMembersFx = membersDomain.effect();
export const resetPasswordFx = membersDomain.effect();

// Members Table Params
export const $isLoading = combine(
  fetchMembersFx.pending,
  fetchFilteredMembersFx.pending,
  (fetchingMembers, fetchingFilteredMembers) => 
    fetchingMembers || fetchingFilteredMembers
);