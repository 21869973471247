import { forward, sample } from "effector";

import { Severity } from "../../app/modules/Common/Snackbar/Enums";
import { addNewChapterFx, updateChapterFx } from "../Chapters/AddEditChapter";
import { activateChapterFx, deactivateChapterFx, fetchChaptersFx, fetchFilteredChaptersFx } from "../Chapters/ManageChapters";
import { addNewMemberFx } from "../Members/AddMember";
import { updateUserFx } from "../Members/ProfilePage";
import { fetchAllMembersFx, fetchChapterMemberCountFx, fetchRecentAlumniFx } from "../Reports";
import { $alertContents, setContents, snackbarApi } from ".";

$alertContents.on(setContents, (_, data) => data);

sample({
  clock: [fetchChaptersFx.failData, fetchFilteredChaptersFx.failData],
  fn: () => ({
    message: "There was an issue retrieving chapters. If the issue persists, please submit a support ticket.",
    severity: Severity.Failure
  }),
  target: setContents
});

sample({
  clock: activateChapterFx.failData,
  fn: () => ({
    message: "There was an issue activating this chapter. If the issue persists, please submit a support ticket.",
    severity: Severity.Failure
  }),
  target: setContents
});

sample({
  clock: deactivateChapterFx.failData,
  fn: () => ({
    message: "There was an issue deactivating this chapter. If the issue persists, please submit a support ticket.",
    severity: Severity.Failure
  }),
  target: setContents
});

sample({
  clock: addNewChapterFx.doneData,
  fn: () => ({
    message: "Chapter has been added successfully",
    severity: Severity.Success
  }),
  target: setContents
});

sample({
  clock: updateChapterFx.doneData,
  fn: () => ({
    message: "Chapter has been updated successfully",
    severity: Severity.Success
  }),
  target: setContents
});

// Member Toast Handling
sample({
  clock: updateUserFx.doneData,
  fn: () => ({
    message: "Member has been updated successfully",
    severity: Severity.Success
  }),
  target: setContents
});

sample({
  clock: updateUserFx.failData,
  fn: () => ({
    message: "There was an issue updating the member",
    severity: Severity.Failure
  }),
  target: setContents
})

sample({
  clock: addNewMemberFx.doneData,
  fn: () => ({
    message: "Member has been added successfully",
    severity: Severity.Success
  }),
  target: setContents
});

sample({
  clock: fetchAllMembersFx.failData,
  fn: () => ({
    message: "There was an issue retrieving all members",
    severity: Severity.Failure
  }),
  target: setContents
});

sample({
  clock: fetchRecentAlumniFx.failData,
  fn: () => ({
    message: "There was an issue retrieving recent alumni",
    severity: Severity.Failure
  }),
  target: setContents
});

sample({
  clock: fetchChapterMemberCountFx.failData,
  fn: () => ({
    message: "There was an issue retrieving chapter member count",
    severity: Severity.Failure
  }),
  target: setContents
});

forward({
  from: setContents,
  to: snackbarApi.show,
});
